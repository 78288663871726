<script setup>
import ShuffleTitle from '@/Last/Blocos/ShuffleTitle.vue'
import Informacoes from '@/Last/Partials/Informacoes.vue'
import FormsWrapper from '@/Last/Partials/FormsWrapper.vue'

import { useFormNewStore } from '@/stores/formNew.js'
import { storeToRefs } from 'pinia'

const { initForms, stepPrev} = useFormNewStore()
const { mostraSteps, steps, step, stepCurrent, lengthString, introText, processingForm } = storeToRefs(useFormNewStore())

const componentMap = {
    Informacoes: Informacoes,
    FormsWrapper: FormsWrapper
};


</script>

<template>
    <div>
      <ShuffleTitle :icon="'/storage/img/new-icons.svg?file#' + step.icon" :title="step.title" :subtitle="lengthString"></ShuffleTitle>
      
      <div class="w-full h-full">
        <Transition name="fade" mode="out-in">
            <div :key="'introducao' + step.slug">
                <div class="conteudo conteudo-y pt-0">
                    <div class="relative">
                        <div class="text-sm w-full md:w-3/4 lg:w-2/3 xl:w-1/2 font-medium text-black/50 opacity-0">{{ introText }}</div>
                        <div class="absolute top-0 left-0 text-sm w-full md:w-3/4 lg:w-2/3 xl:w-1/2 font-medium text-black/50" v-html="step.text"></div>
                    </div>
                </div>
            </div>
        </Transition>
        <div class="border-y border-neutral-200 overflow-hidden">
            <Transition name="top-btn" mode="out-in">
                <div :key="'btnTop' + step.slug">
                  <button v-if="step.slug != 'confirmacao'" @click="!mostraSteps ? initForms() : stepPrev()" class="conteudo py-6 hover:bg-neutral-50 flex items-center space-x-4 text-black" :class="{'opacity-50 cursor-default': processingForm, 'justify-end': !mostraSteps}">
                    <span v-if="!mostraSteps" class="text-lg font-black" v-html="'Iniciar Formulário'"></span>
                    <svg v-if="processingForm" class="size-6"><use class="animate-spin origin-center" xlink:href="/storage/img/new-icons.svg?file#carbon-rotate-180" /></svg>
                    <svg class="size-6" v-else><use :xlink:href="'/storage/img/new-icons.svg?file#carbon-arrow-' + (!mostraSteps ? 'right' : 'left')" /></svg>
                    <span v-if="mostraSteps" class="text-lg font-black" v-html="'Voltar'"></span>
                </button>
              </div>
            </Transition>
        </div>

        <div class="bg-neutral-100">
        <Transition name="fade" mode="out-in">
            <component :key="'conteudo' + step.slug" :is="mostraSteps ? componentMap.FormsWrapper : componentMap.Informacoes"></component>
        </Transition>
      </div>
      </div>
    </div>
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from {
  @apply opacity-0 translate-x-full
}

.fade-leave-to {
  @apply delay-150 opacity-0 -translate-x-full
}

.top-btn-enter-active,
.top-btn-leave-active {
  transition: all 0.5s ease;
}

.top-btn-enter-from {
  @apply translate-y-full
}

.top-btn-leave-to {
  @apply -translate-y-full
}
</style>