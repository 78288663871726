<script setup>
</script>

<template>
    <div class="bg-white conteudo conteudo-y font-medium flex flex-col space-y-2">
            <div class="flex">
                <div class="flex rounded-lg items-center border border-neutral-200">
                    <div class="size-8 flex items-center justify-center bg-neutral-200">
                        <svg class="size-4 text-black"><use xlink:href="/storage/img/new-icons.svg?file#carbon-email" /></svg>
                    </div>
                    <div class="size-8 flex items-center justify-center">
                        <img class="size-4" src="/storage/img/moyg-eye.svg" alt="">
                    </div>
                </div>
            </div>
        <div class=" text-xs text-black">Qualquer dúvida ou problema, não hesite em falar connosco:<br><a class="font-bold" href="mailto:geral@madeofyou.pt">geral@madeofyou.pt</a></div>
    </div>
</template>