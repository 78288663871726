<script setup>
import { watch, ref, onMounted, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useFormNewStore } from '@/stores/formNew.js'
import { useEventoStore } from '@/stores/eventos'

import useErrors from '@/use/error';
import ShuffleText from 'shuffle-text';
const formNewStore = useFormNewStore()
const errors = useErrors()


import Message from './Message.vue'
import SubmeterForm from './SubmeterForm.vue'
import FaleConnosco from './FaleConnosco.vue'


import Eventos from '../Steps/Eventos.vue'
import InfoEmpresa from '../Steps/InfoEmpresa.vue'
import InfoFoodtruck from '../Steps/InfoFoodtruck.vue'
import Documentos from '../Steps/Documentos.vue'
// import Confirmacao from '../Steps/Confirmacao.vue'

const componentMap = {
    Eventos: Eventos,
    InfoEmpresa: InfoEmpresa,
    InfoFoodtruck: InfoFoodtruck,
    Documentos: Documentos,
    // Confirmacao: Confirmacao
};

const transitionName = ref('slide-left')

const beforeEnter = (el) => {
      el.style.transform = transitionName.value === 'slide-left' ? 'translateX(-100%)' : 'translateX(100%)';
}
const enter = (el, done) => {
    setTimeout(() => {
    el.style.transition = 'transform 0.5s';
    el.style.transform = 'translateX(0)';
    }, 50);
    done();
}
const leave = (el, done) => {
    el.style.transition = 'transform 0.5s';
    el.style.transform = transitionName.value === 'slide-left' ? 'translateX(100%)' : 'translateX(-100%)';
    setTimeout(done, 500);
}

// watch: {
//     currentId(newValue, oldValue) {
//       transitionName.value = newValue > oldValue ? 'slide-left' : 'slide-right';
//     }
//   }

  const { stepCurrent } = storeToRefs(formNewStore)

  watch(stepCurrent, (newValue, oldValue) => {
    transitionName.value = newValue > oldValue ? 'slide-right' : 'slide-left';
    console.log('isLoggedIn ref changed, do something!', newValue, oldValue)
  })

  const step = computed(() => {
    return formNewStore.step
})

  const preenchidos = computed(() => {
    if(formNewStore.step.slug == 'introducao' || formNewStore.step.slug == 'confirmacao'){
        return true
    } else if(formNewStore.step.slug == 'eventos-e-sessoes'){
        if(formNewStore.formdata.eventos.length > 0){
            return true
        }
    } else {
        if(formNewStore.step.fields.filter((field) => field.type != 'radio' && !field.valor).length == 0){
            return true
        }
    }
    return false
});

  const valida = () => {

    if(preenchidos){
        formNewStore.stepNext()
    }
        // if(formNewStore.step.slug == 'eventos' &&  formNewStore.formdata.eventos.length > 0){
        //     formNewStore.stepNext()
        // }

        // if(formNewStore.step.slug == 'informacoes-da-empresa' &&  formNewStore.formdata.eventos.length > 0){
        //     formNewStore.stepNext()
        // }

        // var campos = step.fields.filter((field) => field.valor)
        // console.log(campos.length)
  }
</script>

<template>
    <div class="form-wrapper">
        

            <transition name="step" mode="out-in">
            <div class="w-full h-full" :key="formNewStore.step.component">
                <!-- <PerfectScrollbar> -->
                    <component :is="componentMap[formNewStore.step.component]"></component>

                    <Message v-if="formNewStore.step.mensagemErro" :text="formNewStore.step.mensagemErro"></Message>

                    <SubmeterForm></SubmeterForm>

                <!-- </PerfectScrollbar> -->
            </div>
        </transition>

        <FaleConnosco></FaleConnosco>
        
        </div>
</template>

<style>
.form-wrapper .ps {
    @apply w-full h-full overflow-y-auto
  }
.form-wrapper {
    .input-wrapper {
        @apply mb-6;
        .input-text__label {
            @apply block mb-2 text-sm font-bold text-neutral-700;
        }
        .input-text__input {
            @apply bg-neutral-50 border border-neutral-500 text-neutral-900 placeholder-neutral-700 text-sm rounded-lg focus:ring-neutral-500 focus:border-neutral-500 block w-full p-2.5;
        }
        .input-text__mensagem {
            @apply mt-2 text-sm text-neutral-600;
        }

        &.input--success {
            .input-text__label {
                @apply text-green-700;
            }
            .input-text__input {
                @apply bg-green-50 border-green-500 text-green-900 placeholder-green-700 focus:ring-green-500 focus:border-green-500;
            }
            .input-text__mensagem {
                @apply text-green-600;
            }
        }
        &.input--error {
            .input-text__label {
                @apply text-red-700;
            }
            .input-text__input {
                @apply bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500;
            }
            .input-text__mensagem {
                @apply text-red-600;
            }
        }
    }

    
}
</style>
