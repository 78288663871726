<script setup>
import { ref, onMounted, computed } from 'vue'
import { useFormStore } from '@/stores/form.js'
const formStore = useFormStore()

import 'js-circle-progress'

import Olho from '../Mine/Olho.vue'

const year = new Date().getFullYear()

const prev = () => {
  console.log('next', formStore.stepCurrent)
  if(formStore.stepCurrent === 0){
    formStore.mostraSteps = false;
  } else {
    formStore.stepPrev();
  }
}

const next = () => {
  console.log('next', formStore.stepCurrent)
  formStore.stepNext();
}
</script>

<template>
    <div id="Navegacao">
        <button @click="formStore.stepNext" class="fixed top-48 right-24 size-20 rounded-full bg-red-500 group flex items-center space-x-3">
            <div class="hidden group-hover:flex font-bold uppercase leading-[.95em] tracking-tight text-lg">Avançar</div>
            <svg class="size-20 text-black"><use xlink:href="/storage/img/new-icons.svg?file#carbon-arrow-right" /></svg>
        </button>
        <button @click="formStore.stepPrev" class="fixed top-32 right-28 size-12 rounded-full bg-white group flex items-center space-x-3">
            <svg class="size-12 text-black"><use xlink:href="/storage/img/new-icons.svg?file#carbon-arrow-left" /></svg>
            <div class="hidden group-hover:flex font-bold uppercase leading-[.95em] tracking-tight text-lg">Voltar</div>
        </button>
    </div>
</template>

<style scoped>
circle-progress::part(base) {width: 48px; height: auto;}
</style>
