<template>
    <div class="grid grid-cols-1 conteudo-mini conteudo-gap-mini">
    <GroupInput capitulo="1.2" title="Informações da Empresa">
        <div class="grid grid-cols-2 conteudo-gap-mini">
            <div v-for="field in fieldsEmpresa" :class="{'col-span-full': field.slug != 'nif' && field.slug != 'cae'}">
                <InputTexto :name="field.slug" :title="field.title + ' *'" />
            </div>
        </div>
    </GroupInput>

    <GroupInput capitulo="1.2" title="Responsável">
        <div class="grid grid-cols-2 conteudo-gap-mini">
            <div v-for="field in fieldsPessoal" :class="{'col-span-full': field.slug != 'email' && field.slug != 'telefone'}">
                <InputTexto :name="field.slug" :title="field.title + ' *'" />
            </div>
        </div>
    </GroupInput>

<GroupInput capitulo="1.2" title="Redes sociais">
    <div class="grid grid-cols-2 conteudo-gap-mini">
        <div class="col-span-full">
            <InputTexto name="instagram" title="Redes Sociais" />
        </div>
    </div>
</GroupInput>

        
  </div>
</template>

<script setup>
import { Field, ErrorMessage } from 'vee-validate';
import InputTexto from '../InputTexto.vue'
import GroupInput from '../Blocos/GroupInput.vue';

import { useMultiStepStore } from '@/stores/multiStep.js'
const multiStepStore = useMultiStepStore()


const fieldsEmpresa = [
    {
        "title": "Nome Comercial",
        "slug": "nome_comercial"
    },
    {
        "title": "Nome Fiscal",
        "slug": "nome_fiscal"
    },
    {
        "title": "Morada Fiscal",
        "slug": "morada_fiscal",
        "cols": "6"
    },
    {
        "title": "NIF",
        "slug": "nif"
    },
    {
        "title": "CAE",
        "slug": "cae"
    }
];

const fieldsPessoal = [
    {
        "title": "Nome do Responsável da Empresa",
        "slug": "nome_responsavel"
    },
    {
        "title": "Email",
        "slug": "email"
    },
    {
        "title": "Contacto telefónico",
        "slug": "telefone"
    }
]

const redes = [
    {
        "title": "Redes Sociais",
        "slug": "instagram"
    }
]
</script>
