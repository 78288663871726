<script setup>
import {ref, watch, onMounted} from 'vue'

import MainHeader from '@/Last/Main/Header.vue'
import MainFooter from '@/Last/Main/Footer.vue'
import MainContent from '@/Last/Main/Content.vue'

import { useFormNewStore } from '@/stores/formNew.js'
import { useEventoStore } from '@/stores/eventos.js'
const formNewStore = useFormNewStore()
const eventoStore = useEventoStore()

formNewStore.fetchUserInfo()
eventoStore.fetchEvents()

const pronto = ref(false)

defineProps({
    calendar: Object,
    eventos: Object,
    dados: Object
})

watch(() => formNewStore.mostraSteps, () => {
    scrollToTop()
});

watch(() => formNewStore.stepCurrent, () => {
    scrollToTop()
});

onMounted(() => {
    pronto.value = true
})

const scrollToTop = () => {
    if(pronto.value) {
        const duration = 150; // Duration in milliseconds
        const start = window.scrollY;
        const startTime = performance.now();

        const easeInOutQuad = (t) => t < 0.5 ? 2*t*t : -1+(4-2*t)*t;

        const scroll = () => {
            const currentTime = performance.now();
            const timeElapsed = currentTime - startTime;
            const progress = Math.min(timeElapsed / duration, 1);
            const easedProgress = easeInOutQuad(progress);

            window.scrollTo(0, start * (1 - easedProgress));

            if (timeElapsed < duration) {
                requestAnimationFrame(scroll);
            }
        };

        requestAnimationFrame(scroll);
    }
}
</script>

<template>
    <div>
        <div class="w-full grid lg:grid-cols-3 lg:min-h-[100dvh]">
            <div class="relative lg:z-index-50 lg:fixed lg:top-0 lg:left-0 lg:w-1/3 lg:h-[100dvh] lg:overflow-y-auto flex flex-col justify-between bg-gradient-to-br from-green-300 to-green-700">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="absolute top-1/2 right-0 -translate-y-1/3 translate-x-1/3 w-full aspect-square bg-center bg-cover mix-blend-multiply opacity-20" style="background-image:url(/storage/img/foods/fast-food-6.png)"></div>
                </div>
                <MainHeader :mostra-steps="formNewStore.mostraSteps"></MainHeader>
                <div class="hidden lg:block">
                    <MainFooter></MainFooter>
                </div>
            </div>
            <div class="lg:col-start-2 lg:col-span-2 h-full bg-white overflow-hidden">
                <MainContent></MainContent>
            </div>
            <div class="block lg:hidden">
                <MainFooter></MainFooter>
            </div>
        </div>
    </div>
</template>
<style scoped>
</style>