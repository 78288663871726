import _ from 'lodash';
import { defineStore } from 'pinia'
import useErrors from '@/use/error';

// You can name the return value of `defineStore()` anything you want, 
// but it's best to use the name of the store and surround it with `use` 
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
function fieldSimples(field) {
    return {
        slug: field.slug,
        valor: field.valor
    }
};
const steps = [
    {
        index: 0,
        number: 1,
        title: 'Introdução',
        slug: 'introducao',
        component: 'Intro',
        icon: 'carbon-ibm-secure-infrastructure-on-vpc-for-regulated-industries',
        text: 'Estamos entusiasmados para analisar a sua inscrição. Se for selecionado, entraremos em contato diretamente por e-mail.<br><br>Na próxima página, encontrará todas as informações necessárias para concluir a sua inscrição.',
    },
    {
        index: 1,
        number: 2,
        title: 'Eventos e Sessões',
        slug: 'eventos-e-sessoes',
        component: 'Eventos',
        icon: 'carbon-calendar',
        text: 'Por favor selecione os eventos em que tem interesse em participar. Pode fazer a inscrição para mais do que um evento.',
        atencao: 'Para cada sessão de Brunch Electronik é cobrada uma taxa de inscrição.'
    },
    {
        index: 2,
        number: 3,
        title: 'Identificação',
        slug: 'informacoes-da-empresa',
        component: 'InfoEmpresa',
        icon: 'carbon-identification',
        text: 'Preencha com atenção os seguintes campos com todas as informações relevantes sobre a sua empresa. Todos os campos são de preenchimento obrigatório',
        fields: [
            {
              "title": "Nome Comercial",
              "slug": "nome_comercial",
              "valor": null,
              "valid": false,
              "type": "text",
              "required": true
            },
            {
              "title": "Nome Fiscal",
              "slug": "nome_fiscal",
              "valor": null,
              "valid": false,
              "type": "text",
              "required": true
            },
            {
              "title": "Morada Fiscal",
              "slug": "morada_fiscal",
              "valor": null,
              "valid": false,
              "type": "text",
              "required": true,
              "cols": "6"
            },
            {
              "title": "NIF",
              "slug": "nif",
              "valor": null,
              "valid": false,
              "type": "text",
              "required": true
            },
            {
              "title": "CAE",
              "slug": "cae",
              "valor": null,
              "valid": false,
              "type": "text",
              "required": true
            },
            {
              "title": "Nome do Responsável da Empresa",
              "slug": "nome_responsavel",
              "valor": null,
              "valid": false,
              "type": "text",
              "required": true
            },
            {
              "title": "Email",
              "slug": "email",
              "valor": null,
              "valid": false,
              "type": "email",
              "required": true
            },
            {
              "title": "Contacto telefónico",
              "slug": "telefone",
              "valor": null,
              "valid": false,
              "type": "text",
              "required": true
            },
            {
              "title": "Redes Sociais",
              "slug": "instagram",
              "valor": null,
              "valid": false,
              "type": "text",
              "required": true
            }
        ]
    },
    {
        index: 3,
        number: 4,
        title: 'Foodtruck',
        slug: 'foodtruck',
        component: 'InfoFoodtruck',
        icon: 'carbon-delivery-truck',
        text: 'Para nós, poder oferecer uma boa variedade de alimentos e atender aos diferentes tipos de dietas é essencial. Damos prioridade a trucks que correspondam a estes valores.',
        fields: [
            {
            "title": "Comprimento (m)",
            "slug": "comprimento",
            "valor": null,
              "valid": false,
              "type": "number",
            "required": true
            },
            {
            "title": "Largura (m)",
            "slug": "largura",
            "valor": null,
              "valid": false,
              "type": "number",
            "required": true
            },
            {
            "title": "Altura (m)",
            "slug": "altura",
            "valor": null,
              "valid": false,
              "type": "number",
            "required": true
            },
            {
            "title": "Peso (kg)",
            "slug": "peso",
            "valor": null,
              "valid": false,
              "type": "number",
            "required": true
            },
            {
            "title": "Necessidades elétricas",
            "slug": "necessidades_eletricas",
            "valor": null,
              "valid": false,
              "type": "text",
            "required": true
            },
            {
            "title": "Tem esgoto e água autonomos?",
            "slug": "esgoto_autonomo",
            "valor": null,
              "valid": false,
              "type": "text",
            "required": true
            },
            {
            "title": "Tipo de cozinha",
            "slug": "tipo_cozinha",
            "valor": null,
              "valid": false,
              "type": "text",
            "required": true,
            "cols": "6"
            },
            {
            "title": "Tem pratos vegetarianos?",
            "slug": "tem_vegetariano",
            "valor": false,
              "valid": false,
              "type": "radio",
            "required": true,
            "options": {
            "sim": "Sim",
            "nao": "Não"
            }
            },
            {
            "title": "Tem pratos vegan?",
            "slug": "tem_vegan",
            "valor": false,
              "valid": false,
              "type": "radio",
            "required": true,
            "options": {
            "sim": "Sim",
            "nao": "Não"
            }
            },
            {
            "title": "Tem opções sem gluten?",
            "slug": "tem_gluten",
            "valor": false,
              "valid": false,
              "type": "radio",
            "required": true,
            "options": {
            "sim": "Sim",
            "nao": "Não"
            }
            },
            {
            "title": "Menú (preços e ingredientes)",
            "slug": "menu",
            "valor": null,
              "valid": false,
              "type": "file",
            "required": true,
            "legenda": "File should be of format .mp4, .avi, .mov or .mkv"
            },
            {
            "title": "Fotos da Truck",
            "slug": "fotos",
            "valor": [],
              "valid": false,
              "type": "file",
            "required": true,
            "legenda": "File should be of format .mp4, .avi, .mov or .mkv",
            "multiple": 1
            }
        ]
    },
    {
        index: 4,
        number: 5,
        title: 'Documentos',
        slug: 'documentos',
        component: 'Documentos',
        icon: 'carbon-document-attachment',
        text: 'Este campo serve essencialmente para poderem preparar a documentação necessária no caso de serem selecionados.',
        fields: [
            {
            "title": "Ficha Técnica",
            "slug": "ficha_tecnica",
            "valor": 0,
              "valid": false,
              "type": "file",
            "required": true
            },
            {
            "title": "Memória descritiva'",
            "slug": "memoria_descritiva",
            "valor": 0,
              "valid": false,
              "type": "file",
            "required": true
            }
        ]
    },
    {
        index: 5,
        number: 6,
        title: 'Confirmação',
        slug: 'confirmacao',
        component: 'Confirmacao',
        icon: 'carbon-checkmark',
        text: 'Obrigado pela sua candidatura. Se for selecionado, entraremos em contato diretamente por e-mail.',
        footer: 'Libero quidem atque ad commodi ea sint reprehenderit tempore voluptates odit esse non neque.'
    }
];
const pessoaisStep = steps.find((stepItem) => {
    return stepItem.slug == 'informacoes-da-empresa'
})
const pessoaisFields = pessoaisStep.fields

const foodtruckStep = steps.find((stepItem) => {
    return stepItem.slug == 'foodtruck'
})
const foodtruckFields = foodtruckStep.fields

const documentosStep = steps.find((stepItem) => {
    return stepItem.slug == 'documentos'
})
const documentosFields = documentosStep.fields



export const useFormStore = defineStore('form', {
    state: () => ({
        calendar: {},
        fields: {},
        mostraSteps: false,
        steps: steps,
        // step: {},
        stepCurrent: 0,
        vaiEntrar: 0,
        unlocked: 0,
        eventos: [],

        errors: {},
        erros: {},
        processingForm: false,

        registoId: null,
        pessoais: pessoaisFields,
        foodtruck: foodtruckFields,
        documentos: documentosFields,


        // pessoais: [
        //     {
        //       "slug": "nome_comercial",
        //       "valor": "nome_comercial"
        //     },
        //     {
        //       "slug": "nome_fiscal",
        //       "valor": "nome_fiscal"
        //     },
        //     {
        //       "slug": "morada_fiscal",
        //       "valor": "morada_fiscal"
        //     },
        //     {
        //       "slug": "nif",
        //       "valor": "nif"
        //     },
        //     {
        //       "slug": "cae",
        //       "valor": "cae"
        //     },
        //     {
        //       "slug": "nome_responsavel",
        //       "valor": "nome_responsavel"
        //     },
        //     {
        //       "slug": "email",
        //       "valor": "email"
        //     },
        //     {
        //       "slug": "telefone",
        //       "valor": "telefone"
        //     },
        //     {
        //       "slug": "instagram",
        //       "valor": "instagram"
        //     },
        //     {
        //       "slug": "inicio_empresa",
        //       "valor": "inicio_empresa"
        //     }
        //   ]


            // {
            //     "nome_comercial": {value: null, valid: false},
            //     "nome_fiscal": {value: null, valid: false},
            //     "morada_fiscal": {value: null, valid: false},
            //     "nif": {value: null, valid: false},
            //     "cae": {value: null, valid: false},
            //     "nome_responsavel": {value: null, valid: false},
            //     "email": {value: null, valid: false},
            //     "telefone": {value: null, valid: false},
            //     "instagram": {value: null, valid: false},
            //     "inicio_empresa": {value: null, valid: false}
            // }
        // },
    }),
    getters: {
        percentagem(){
            return (this.stepCurrent - 1) / (this.steps.length - 1) * 100
        },
        stepsNew(){
            return this.steps
        },
        step(){
            return this.steps[this.stepCurrent]
        },
        formdata(){
            var pessoais = {}
            pessoais['id'] = this.registoId
            for (let index = 0; index < this.pessoais.length; index++) {
                const element = this.pessoais[index];
                pessoais[element.slug] = element.valor
            }
            pessoais['eventos'] = this.eventos
            return pessoais
        },
        foodtruckFormdata(){
            var foodtruck = {}
            foodtruck['id'] = this.registoId
            for (let index = 0; index < this.foodtruck.length; index++) {
                const element = this.foodtruck[index];
                // console.log('element', this.foodtruck[index])
                foodtruck[element.slug] = element.valor
            }
            return foodtruck
        },
        documentosFormdata(){
            var documentos = {}
            documentos['id'] = this.registoId
            for (let index = 0; index < this.documentos.length; index++) {
                const element = this.documentos[index];
                // console.log('element', this.documentos[index])
                documentos[element.slug] = element.valor
            }
            return documentos
        },
        // pessoais(){
        //     var pessoais = this.steps.find((stepItem) => {
        //         return stepItem.index == 2
        //     })
        //     var preenchidos = pessoais.fields.filter((field) => {
        //         return field.valor
        //     })
        //     var final = []
        //     for (let index = 0; index < preenchidos.length; index++) {
        //         const element = preenchidos[index];
        //         if(element.valor) final[element.slug] = element.valor
        //     }
        //     return preenchidos
        // }
    },
    actions: {
        fakeContent(number=2){
            this.mostraSteps = false
            this.stepCurrent = 0
            this.unlocked = 5
            this.eventos = [1,2,3]

            for (let index = 0; index < this.pessoais.length; index++) {
                this.pessoais[index]['valor'] = this.pessoais[index]['title']
                if(this.pessoais[index]['slug'] == 'inicio_empresa') this.pessoais[index]['valor'] = '2024-05-30'
                if(this.pessoais[index]['slug'] == 'email') this.pessoais[index]['valor'] = 'email@email.com'
            }

            for (let index = 0; index < this.foodtruck.length; index++) {
                this.foodtruck[index]['valor'] = this.foodtruck[index]['title']
                if(this.foodtruck[index]['slug'] == 'comprimento') this.foodtruck[index]['valor'] = 20
                if(this.foodtruck[index]['slug'] == 'largura') this.foodtruck[index]['valor'] = 30
                if(this.foodtruck[index]['slug'] == 'altura') this.foodtruck[index]['valor'] = 40
                if(this.foodtruck[index]['slug'] == 'peso') this.foodtruck[index]['valor'] = 50
                if(this.foodtruck[index]['slug'] == 'tem_vegetariano') this.foodtruck[index]['valor'] = false
                if(this.foodtruck[index]['slug'] == 'tem_vegan') this.foodtruck[index]['valor'] = false
                if(this.foodtruck[index]['slug'] == 'tem_gluten') this.foodtruck[index]['valor'] = false
                if(this.foodtruck[index]['slug'] == 'menu') this.foodtruck[index]['valor'] = 0
                if(this.foodtruck[index]['slug'] == 'fotos') this.foodtruck[index]['valor'] = []
            }
        },
        setCalendar(calendar){
            this.calendar = calendar;
        },
        setStep(step){
            // this.step = this.steps.find((stepItem) => {
            //     return stepItem.number == step
            // })

            this.vaiEntrar = this.stepCurrent;
            this.mostraSteps = true;
            this.stepCurrent = step;

            if(this.unlocked < step) this.unlocked = step;
            // this.stepCurrent = step;
            // if(this.stepCurrent >= 0) this.mostraSteps = true;
            
            // this.parouProcesso(step)
            //this.processingForm = false
        },
        parouProcesso(){
            setTimeout((step) => {
                this.processingForm = false
            }, 2000)
        },
        previous(){
            this.stepPrev();
        },
        next(){
            this.stepNext();
        },
        stepPrev(){
            if(this.stepCurrent === 0){
                this.vaiEntrar = this.stepCurrent - 1;
                this.mostraSteps = false
            } else {
                this.mostraSteps = true
            }
            if(this.stepCurrent > 0){
                // this.processingForm = true
                this.setStep(this.stepCurrent - 1)
            } 
        },
        stepNext(){
            this.processingForm = true
            // if(!this.stepCurrent >=0) this.processingForm = true
            console.log('this.stepCurrent', this.stepCurrent)
            if(this.stepCurrent == 0) {
                console.log(2)
                this.setStep(this.mostraSteps ? 1 : 0)
                this.processingForm = false
            } else if(this.stepCurrent == 1) {
                console.log(3)
                if(this.formdata.eventos.length == 0){
                    console.log(this.formdata)
                    this.step.invalid = true
                } else {
                                    console.log(6)
                    this.step.invalid = false
                    this.setStep(2)
                }
                this.processingForm = false
            } else if(this.stepCurrent == 2) {
                console.log(4)
                var valido = true
                var nulo = _.has(this.formdata.pessoais, null);
                console.log('nulo', nulo)
                if(!nulo){
                    
                    
                    const errors = useErrors()
                    
                    // axios.post('/submit-form', this.formdata).then((response) => {
                    //     form.processing = false;
                
                    //     console.log(response.errors)
                    //     // closeModal();
                    //     // nextTick().then(() => emit('confirmed'));
                
                    // }).catch(error => {
                    //     console.log(error.response.data)

                    // //    errors.record(error.errors)//, 'form')
                    // //    console.log(errors.errors)

                        
                    //     // form.processing = false;
                    //     // form.error = error.response.data.errors.password[0];
                    //     // passwordInput.value.focus();
                    // });

                    

                    this.store(this.formdata).then(response => {
                        console.log(response)
                        this.registoId = response
                        // PROCESSING
                        // this.parouProcesso()
                        this.processingForm = false
                        this.errors = {};
                        this.step.invalid = false;
                        this.setStep(3)
                        // SUCCESS MESSAGE
                        console.log('sucesso')
                    }).catch(error => {
                        // it has errors 
                        // PROCESSING
                        // this.parouProcesso()
                        this.processingForm = false
                        this.step.invalid = true

                        // now will set those errors
                        errors.record(error.errors)
                        this.errors = errors.errors;
                    })

                    
                    
                    
                    
                    
                    // this.setStep(3)
                } else {
                    this.step.invalid = true
                }
            } else if(this.stepCurrent == 3) {
                var valido = true
                var nulo = _.has(this.foodtruckFormdata, null);
                console.log('nulo', nulo)
                if(!nulo){
                    
                    
                    const errors = useErrors()

                    

                    this.store(this.foodtruckFormdata, '/submit-form-foodtruck').then(response => {
                        console.log(response)
                        this.registoId = response
                        // PROCESSING
                        // this.parouProcesso()
                        this.processingForm = false
                        this.errors = {};
                        this.step.invalid = false;
                        this.setStep(4)
                        // SUCCESS MESSAGE
                        console.log('sucesso')
                    }).catch(error => {
                        // it has errors 
                        // PROCESSING
                        // this.parouProcesso()
                        this.processingForm = false
                        this.step.invalid = true

                        // now will set those errors
                        errors.record(error.errors)
                        this.errors = errors.errors;
                    })

                    
                    
                    
                    
                    
                    // this.setStep(3)
                } else {
                    this.step.invalid = true
                }
            } else if(this.stepCurrent == 4) {
                var valido = true
                var nulo = _.has(this.documentosFormdata, null);
                console.log('nulo', nulo)
                if(!nulo){
                    
                    
                    const errors = useErrors()

                    

                    this.store(this.documentosFormdata, '/submit-form-documentos').then(response => {
                        console.log(response)
                        this.registoId = response
                        // PROCESSING
                        // this.parouProcesso()
                        this.processingForm = false
                        this.errors = {};
                        this.step.invalid = false;
                        this.setStep(5)
                        // SUCCESS MESSAGE
                        console.log('sucesso')
                    }).catch(error => {
                        // it has errors 
                        // PROCESSING
                        // this.parouProcesso()
                        this.processingForm = false
                        this.step.invalid = true

                        // now will set those errors
                        errors.record(error.errors)
                        this.errors = errors.errors;
                    })

                    
                    
                    
                    
                    
                    // this.setStep(3)
                } else {
                    this.step.invalid = true
                }
            }
            // this.parouProcesso()
            // this.processingForm = false
            // if(this.stepCurrent < this.unlocked && this.stepCurrent < this.steps.length){
            //     this.setStep(this.stepCurrent + 1)
            // }
        },
        reset(){
            this.unlocked = 0;
            this.setStep(0);
        },
        async store (data, url = '/submit-form') {
            try {
                const response = await axios.post(url, data)
                // RETURN REQUEST
                return response.data
            } catch (error) {
                console.log('my eerorr', error.response.data.errors)
                this.erros = error.response.data.errors;
                throw error.response.data
            }
        }
    },
    // persist: true,
})