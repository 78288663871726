<template>
    <div>
        <transition name="info-foodtruck--truck">
            <div v-if="pronto" class="absolute top-[56dvh] left-0 mix-blend-multiply">
                <img class="size-48" src="/storage/img/trucks/1.svg" alt="">
            </div>
        </transition>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
const formStore = useFormStore()


const pronto = ref(false)
onMounted(() => {
    pronto.value = true
});
</script>

<style scoped>
  .info-foodtruck--truck-enter-active,
  .info-foodtruck--truck-leave-active {
    @apply transition-all duration-[4s] ease-out;
  }
  .info-foodtruck--truck-enter-from {
    @apply top-0 left-[100dvw]
  }
  .info-foodtruck--truck-leave-to {
    @apply top-[112dvh] left-[-100dvw]
  }
</style>
