<template>
    <div>
      <form @submit="onSubmit">
      <slot />
  
      <div>
        <button type="submit">{{ isLastStep ? 'Submit' : 'Next' }}</button>
      </div>

      <button type="submit" :disabled="!meta.valid || meta.pending || !meta.dirty ? true : false" class="conteudo py-6 border-y border-neutral-200 hover:bg-neutral-50 flex items-center justify-end space-x-4 text-black" :class="{'hover:!bg-red-50 text-opacity-20 cursor-default': !meta.valid || meta.pending || !meta.dirty}">
            <span class="text-lg font-black">{{ isLastStep ? 'Submeter' : 'Avançar' }}</span>
            <svg v-if="meta.pending" class="size-10"><use class="animate-spin origin-center" xlink:href="/storage/img/new-icons.svg?file#carbon-rotate-180" /></svg>
            <svg class="size-10" v-else><use xlink:href="/storage/img/new-icons.svg?file#carbon-arrow-right" /></svg>
        </button>
    </form>



<Message v-if="!meta.valid" :text="'O formulário contém erros que devem ser corrigidos.'"></Message>

  <!-- <SubmeterForm></SubmeterForm> -->
  <pre>errorMessage:<br>{{ errorMessage }}</pre>
<pre>Errors:<br>{{ errors }}</pre>
<pre>errorBag:<br>{{ errorBag }}</pre>
<pre>values:<br>{{ values }}</pre>
<pre>meta:<br>{{ meta }}</pre>
</div>
  </template>
  
  <script setup lang="ts">
  import { useForm } from 'vee-validate';
  import { ref, computed, provide } from 'vue';
  import { useMultiStepStore } from '@/stores/multiStep.js'

  import Message from '../Blocos/Message.vue'
import SubmeterForm from '../Blocos/SubmeterForm.vue'

  const multiStepStore = useMultiStepStore()
  
  const props = defineProps({
    validationSchema: {
      type: Array,
      required: true
    },
    currentStepIdx: Number
  });
  
  const emit = defineEmits(['submit', 'avanca']);
  
  // Inject the live ref of the current index to child components
  // will be used to toggle each form-step visibility
  // provide('CURRENT_STEP_INDEX', props.currentStepIdx);
  
  // if this is the last step
  const isLastStep = computed(() => {
    return props.currentStepIdx === 3;
  });
  
  // If the `previous` button should appear
  const hasPrevious = computed(() => {
    return props.currentStepIdx > 0;
  });
  
  // extracts the indivdual step schema
  const currentSchema = computed(() => {
    return props.validationSchema[props.currentStepIdx];
  });
  
  const { errors, errorMessage, errorBag, values, meta, handleSubmit } = useForm({
    // vee-validate will be aware of computed schema changes
    validationSchema: currentSchema,
    // turn this on so each step values won't get removed when you move back or to the next step
    keepValuesOnUnmount: true,
  });
  
  // We are using the "submit" handler to progress to next steps
  // and to submit the form if its the last step
  const onSubmit = handleSubmit((values) => {
    if (!isLastStep.value) {
      // currentStepIdx.value++;
      emit('avanca', true);
  
      return;
    }
  
    // Let the parent know the form was filled across all steps
    emit('submit', values);
  });
  </script>
  