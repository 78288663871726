import { defineStore } from 'pinia';
const steps = [
    // {
    //     index: 0,
    //     number: 1,
    //     title: 'Introdução',
    //     slug: 'introducao',
    //     component: 'Intro',
    //     icon: 'carbon-ibm-secure-infrastructure-on-vpc-for-regulated-industries',
    //     text: 'Estamos entusiasmados para analisar a sua inscrição. Se for selecionado, entraremos em contato diretamente por e-mail.<br><br>Na próxima página, encontrará todas as informações necessárias para concluir a sua inscrição.',
    // },
    {
        index: 1,
        number: 2,
        title: 'Eventos e Sessões',
        slug: 'eventos-e-sessoes',
        component: 'Eventos',
        icon: 'carbon-calendar',
        text: 'Por favor selecione os eventos em que tem interesse em participar. Pode fazer a inscrição para mais do que um evento.',
        atencao: 'Para cada sessão de Brunch Electronik é cobrada uma taxa de inscrição.'
    },
    {
        index: 2,
        number: 3,
        title: 'Identificação',
        slug: 'informacoes-da-empresa',
        component: 'InfoEmpresa',
        icon: 'carbon-identification',
        text: 'Preencha com atenção os seguintes campos com todas as informações relevantes sobre a sua empresa. Todos os campos são de preenchimento obrigatório',
        fields: [
            {
              "title": "Nome Comercial",
              "slug": "nome_comercial",
              "valor": null,
              "valid": false,
              "type": "text",
              "required": true
            },
            {
              "title": "Nome Fiscal",
              "slug": "nome_fiscal",
              "valor": null,
              "valid": false,
              "type": "text",
              "required": true
            },
            {
              "title": "Morada Fiscal",
              "slug": "morada_fiscal",
              "valor": null,
              "valid": false,
              "type": "text",
              "required": true,
              "cols": "6"
            },
            {
              "title": "NIF",
              "slug": "nif",
              "valor": null,
              "valid": false,
              "type": "text",
              "required": true
            },
            {
              "title": "CAE",
              "slug": "cae",
              "valor": null,
              "valid": false,
              "type": "text",
              "required": true
            },
            {
              "title": "Nome do Responsável da Empresa",
              "slug": "nome_responsavel",
              "valor": null,
              "valid": false,
              "type": "text",
              "required": true
            },
            {
              "title": "Email",
              "slug": "email",
              "valor": null,
              "valid": false,
              "type": "email",
              "required": true
            },
            {
              "title": "Contacto telefónico",
              "slug": "telefone",
              "valor": null,
              "valid": false,
              "type": "text",
              "required": true
            },
            {
              "title": "Redes Sociais",
              "slug": "instagram",
              "valor": null,
              "valid": false,
              "type": "text",
              "required": false
            }
        ]
    },
    {
        index: 3,
        number: 4,
        title: 'Foodtruck',
        slug: 'foodtruck',
        component: 'InfoFoodtruck',
        icon: 'carbon-delivery-truck',
        text: 'Para nós, poder oferecer uma boa variedade de alimentos e atender aos diferentes tipos de dietas é essencial. Damos prioridade a trucks que correspondam a estes valores.',
        fields: [
            {
            "title": "Comprimento (m)",
            "slug": "comprimento",
            "valor": null,
              "valid": false,
              "type": "number",
            "required": true
            },
            {
            "title": "Largura (m)",
            "slug": "largura",
            "valor": null,
              "valid": false,
              "type": "number",
            "required": true
            },
            {
            "title": "Altura (m)",
            "slug": "altura",
            "valor": null,
              "valid": false,
              "type": "number",
            "required": true
            },
            {
            "title": "Peso (kg)",
            "slug": "peso",
            "valor": null,
              "valid": false,
              "type": "number",
            "required": true
            },
            {
            "title": "Necessidades elétricas",
            "slug": "necessidades_eletricas",
            "valor": null,
              "valid": false,
              "type": "text",
            "required": true
            },
            {
            "title": "Tem esgoto e água autonomos?",
            "slug": "esgoto_autonomo",
            "valor": null,
              "valid": false,
              "type": "text",
            "required": true
            },
            {
            "title": "Tipo de cozinha",
            "slug": "tipo_cozinha",
            "valor": null,
              "valid": false,
              "type": "text",
            "required": true,
            "cols": "6"
            },
            {
            "title": "Tem pratos vegetarianos?",
            "slug": "tem_vegetariano",
            "valor": 0,
              "valid": true,
              "type": "radio",
            "required": true
            },
            {
            "title": "Tem pratos vegan?",
            "slug": "tem_vegan",
            "valor": 0,
              "valid": true,
              "type": "radio",
            "required": true
            },
            {
            "title": "Tem opções sem gluten?",
            "slug": "tem_gluten",
            "valor": 0,
              "valid": true,
              "type": "radio",
            "required": true
            },
            {
            "title": "Menú (preços e ingredientes)",
            "slug": "menu",
            "valor": null,
              "valid": false,
              "type": "file",
            "required": false,
            "legenda": "File should be of format .mp4, .avi, .mov or .mkv"
            },
            {
            "title": "Fotos da Truck",
            "slug": "fotos",
            "valor": [],
              "valid": false,
              "type": "file",
            "required": false,
            "legenda": "File should be of format .mp4, .avi, .mov or .mkv",
            "multiple": 1
            }
        ]
    },
    {
        index: 4,
        number: 5,
        title: 'Documentos',
        slug: 'documentos',
        component: 'Documentos',
        icon: 'carbon-document-attachment',
        text: 'Este campo serve essencialmente para poderem preparar a documentação necessária no caso de serem selecionados.',
        fields: [
            {
            "title": "Ficha Técnica",
            "slug": "ficha_tecnica",
            "valor": 0,
              "valid": false,
              "type": "file",
            "required": true
            },
            {
            "title": "Memória descritiva'",
            "slug": "memoria_descritiva",
            "valor": 0,
              "valid": false,
              "type": "file",
            "required": true
            }
        ]
    },
    {
        index: 5,
        number: 6,
        title: 'Confirmação',
        slug: 'confirmacao',
        component: 'Confirmacao',
        icon: 'carbon-checkmark',
        text: 'Obrigado pela sua candidatura. Se for selecionado, entraremos em contato diretamente por e-mail.',
        footer: 'Libero quidem atque ad commodi ea sint reprehenderit tempore voluptates odit esse non neque.'
    }
];
export const useMultiStepStore = defineStore('multiStep', {
    
    state: () => ({
        stepCounter: 0,
        currentStepIdx: 0,

        steps: steps,
        mostraSteps: false,

        errors: {},
        errorBag: {},
        values: {},
        meta: {}
    }),
    getters: {
        step(){
            return this.steps[this.currentStepIdx]
        },
    },
    actions: {
        setInicio() {
            this.mostraSteps = true
        },
        goToPrev() {
            if (this.currentStepIdx === 0) {
                this.mostraSteps = false;
            } else {
            
            this.currentStepIdx--;
        }
        }
    },
    persist: true,
})