<script setup>
import {ref, computed} from 'vue'

const props = defineProps({
    step: Object,
    field: Object
})

props.field.valid = computed(() => {
    return props.field.valor === 0 || props.field.valor === 1
});
props.field.errorMessage = computed(() => {
    return !props.field.valid ? 'Tem de escolher uma opção.' : false
});
</script>

<template>
    <!-- <input v-model="input" v-bind="inputAttrs" />

<pre>values: {{ values }}</pre>
<pre>errors: {{ errors }}</pre>
<pre>errors: {{ meta }}</pre>


    <pre>{{ field }}</pre>
    ola -->


    <label class="block">
      <div class="w-full aspect-square flex flex-col space-y-4 justify-between rounded-lg border border-neutral-200 p-4">
        <p class="text-black text-xs font-bold min-h-[2em]" :class="{'text-red-500': !field.valid}">{{field.title }} {{field.required ? '*' : '' }}</p>
        <div class="mt-1 flex flex-col space-y-4">
          <button @click="field.valor = 1" class="flex items-center space-x-3">
            <div class="flex-none relative size-6 border text-white border-black/30 bg-white/0 rounded-full flex items-center justify-center transition-all duration-500" :class="{'!text-white !bg-green-500 border-green-500': field.valor === 1}">
              <transition name="check" mode="out-in">
                  <svg v-if="field.valor === 1"
                      class="check-icon size-4">
                      <use xlink:href="/storage/img/new-icons.svg?file#carbon-checkmark" /></svg>
              </transition>
            </div>
            <div class="grow text-left font-bold text-sm" :class="{'text-red-500': !field.valid}">Sim</div>
          </button>
          <button @click="field.valor = 0" class="flex items-center space-x-3">
            <div class="flex-none relative size-6 border text-white border-black/30 bg-white/0 rounded-full flex items-center justify-center transition-all duration-500" :class="{'!text-white !bg-green-500 border-green-500': field.valor === 0}">
              <transition name="check" mode="out-in">
                  <svg v-if="field.valor === 0"
                      class="check-icon size-4">
                      <use xlink:href="/storage/img/new-icons.svg?file#carbon-checkmark" /></svg>
              </transition>
            </div>
            <div class="grow text-left font-bold text-sm" :class="{'text-red-500': !field.valid}">Não</div>
          </button>



          
        </div>
      </div>
        <span v-if="field.errorMessage && !field.valid" class="text-xs" :class="{'text-red-500': !field.valid}">{{field.errorMessage }}</span>
    </label>
</template>
<style scoped>
</style>