<script setup>
import { Field, ErrorMessage, useIsFormValid } from 'vee-validate';
import * as yup from 'yup';

import { useMultiStepStore } from '@/stores/multiStep.js'
const multiStepStore = useMultiStepStore()

import FormWizard from '../FormWizard.vue';
import FormStep from '../FormStep.vue';

import Eventos from '../Steps/Eventos.vue'
import InfoEmpresa from '../Steps/InfoEmpresa.vue'
import InfoFoodtruck from '../Steps/InfoFoodtruck.vue'
import Documentos from '../Steps/Documentos.vue'
// import Confirmacao from '../Steps/Confirmacao.vue'

// import Message from './Message.vue'
// import SubmeterForm from './SubmeterForm.vue'
import FaleConnosco from './FaleConnosco.vue'

const componentMap = {
    Eventos: Eventos,
    InfoEmpresa: InfoEmpresa,
    InfoFoodtruck: InfoFoodtruck,
    Documentos: Documentos,
    // Confirmacao: Confirmacao
};

// break down the validation steps into multiple schemas
const validationSchema = [
    yup.object({
      eventos: yup.array().of(yup.number().required().required()).min(1, "Tem de escolher pelo menos uma sessão.")
  }),
  yup.object({
    nome_comercial: yup.string().required().label('Nome Comercial'),
    nome_fiscal: yup.string().required().label('Nome Fiscal'),
    morada_fiscal: yup.string().required().label('Morada Fiscal'),
    nif: yup.string().required().label('NIF'),
    cae: yup.string().required().label('CAE'),
    nome_responsavel: yup.string().required().label('Nome do Responsável da Empresa'),
    email: yup.string().required().email().label('Email'),
    telefone: yup.string().required().label('Contacto telefónico'),
  }),
  yup.object({
    password: yup.string().min(8).required(),
    confirmPass: yup
      .string()
      .required()
      .oneOf([yup.ref('password')], 'Passwords must match'),
  }),
  yup.object({
    favoriteDrink: yup
      .string()
      .required()
      .oneOf(['coffee', 'tea', 'soda'], 'Choose a drink'),
  }),
];

const onAvanca = () => {
    multiStepStore.currentStepIdx++
}

/**
 * Only Called when the last step is submitted
 */
function onSubmit(formData) {
  console.log(JSON.stringify(formData, null, 2));
}
</script>

<template>
    <div class="bg-neutral-50">
      <FormWizard :validation-schema="validationSchema" @submit="onSubmit" @avanca="onAvanca" :current-step-idx="multiStepStore.currentStepIdx">
        <Transition name="fade" mode="out-in">
          <div class="w-full h-full" :key="multiStepStore.step.component">
        <component :is="componentMap[multiStepStore.step.component]"></component>
      </div>
      </Transition>
    </FormWizard>

            <!-- <transition name="step" mode="out-in">
            <div class="w-full h-full" :key="multiStepStore.step.component"> -->
                <!-- <PerfectScrollbar> -->
                    <!-- <component :is="componentMap[formNewStore.step.component]"></component> -->

                    <!-- <Message v-if="!useIsFormValid()" :text="'O formulário contém erros que devem ser corrigidos.'"></Message>

                    <SubmeterForm></SubmeterForm> -->

                <!-- </PerfectScrollbar> -->
            <!-- </div>
        </transition> -->

        <FaleConnosco></FaleConnosco>
        
        </div>
</template>

<style scoped>
/* .form-wrapper .ps {
    @apply w-full h-full overflow-y-auto
  }
.form-wrapper {
    .input-wrapper {
        @apply mb-6;
        .input-text__label {
            @apply block mb-2 text-sm font-bold text-neutral-700;
        }
        .input-text__input {
            @apply bg-neutral-50 border border-neutral-500 text-neutral-900 placeholder-neutral-700 text-sm rounded-lg focus:ring-neutral-500 focus:border-neutral-500 block w-full p-2.5;
        }
        .input-text__mensagem {
            @apply mt-2 text-sm text-neutral-600;
        }

        &.input--success {
            .input-text__label {
                @apply text-green-700;
            }
            .input-text__input {
                @apply bg-green-50 border-green-500 text-green-900 placeholder-green-700 focus:ring-green-500 focus:border-green-500;
            }
            .input-text__mensagem {
                @apply text-green-600;
            }
        }
        &.input--error {
            .input-text__label {
                @apply text-red-700;
            }
            .input-text__input {
                @apply bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500;
            }
            .input-text__mensagem {
                @apply text-red-600;
            }
        }
    }

    
} */
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from {
  @apply opacity-0 translate-x-full
}

.fade-leave-to {
  @apply delay-150 opacity-0 -translate-x-full
}
</style>

