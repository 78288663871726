<template>
    <div class="p-6">
        <div class="grid grid-cols-1 gap-6">
            <div v-for="field in formStore.pessoais" class="input-wrapper" :class="{'input--success': 1>2, 'input--error': (formStore.erros && formStore.erros[field.slug]) || !field.valor }">
		        <label :for="field.slug" class="input-text__label">{{field.title }} {{field.required ? '*' : '' }}</label>
                <p v-if="field.slug == 'instagram'" class="text-xs pb-4">Por favor insira o perfil de instagram da truck. Se não tiver instagram pode adicionar o facebook ou website. (se calhar é melhor colocar uma opção tipo “não tenho redes sociais” para poderem selecionar.</p>
		        <input :type="field.type" :name="field.slug" class="input-text__input" :placeholder="field.placeholder ?? ''" :required="true" v-model="field.valor">
		        <p class="input-text__mensagem" v-if="formStore.erros && formStore.erros[field.slug]" v-html="formStore.erros[field.slug][0]"></p>
	        </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useFormStore } from '@/stores/form.js'
const formStore = useFormStore()
import useErrors from '@/use/error';

const errors = useErrors()



const mudouValor = (slug, valor) => {
    console.log('joao', 'mudou', )
    formStore.formdata.pessoais[slug] = valor
}
const valida = () => {
    formStore.step.fields.forEach(field => {
        if(!field.valor) field.invalid = true
    });

    console.log(formStore.steps)

    axios.post('/submit-form', formStore.step.fields).then(() => {
        form.processing = false;

        console.log()
        // closeModal();
        // nextTick().then(() => emit('confirmed'));

    }).catch(error => {
        console.log(error.response.data)
        
        // form.processing = false;
        // form.error = error.response.data.errors.password[0];
        // passwordInput.value.focus();
    });
}

// const preenchidos = computed(() => {
//     var campos = formStore.step.fields.filter((field) => field.valor)
//     return campos.length
// });

const preenchidos = computed(() => {
    // var campos = formStore.step.fields.filter((field) => field.valor)
    // return campos.length
    return formStore.formdata.eventos.length > 0
});
</script>

<style scoped>
  .text-input {
    @apply block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-verde-600
  }
.text-label {
    @apply absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-verde-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6
}

.area-icon__wrapper {
    @apply flex size-16 shrink-0 items-center justify-center rounded-full bg-verde-500/10;
    .area-icon {
    @apply size-8 text-verde-500;
    }
}

  .invalid {
    input,
    .text-input {
        @apply text-red-500 border-red-500 focus:border-red-500
    }
    label,
    .text-label {
        @apply text-red-500 peer-focus:text-red-500
    }

    .area-icon__wrapper {
    @apply bg-red-500/10;
    .area-icon {
    @apply text-red-500;
    }
    }
  }
</style>
