<script setup>
    import {ref, watch} from 'vue'
    import ShuffleText from 'shuffle-text';
    
    const props = defineProps({
        title: String,
        subtitle: String,
        icon: String,
        logo: Boolean
    })
    
    const titulo = props.title
    const subtitulo = props.subtitle
    const mudaTitulo = ref(null)
    const mudaSubtitulo = ref(null)
    
    watch(() => props.title, (newTitle) => {
        mudaTitulo.value.innerText = newTitle;
        var text = new ShuffleText(mudaTitulo.value);
        text.start();
    });
    watch(() => props.subtitle, (newSubtitle) => {
        mudaSubtitulo.value.innerText = newSubtitle;
        var subtext = new ShuffleText(mudaSubtitulo.value);
        subtext.start();
    });
</script>

<template>
    <div class="conteudo conteudo-y lg:!conteudo-mini-b">
        <img v-if="logo" class="relative size-10 mb-3" src="/storage/img/moyg-eye.svg" alt="">
        <div v-else class="relative size-10 mb-3">
            <Transition name="top-icon">
                <svg :key="icon" class="absolute top-1/2 -translate-x-1/2 left-1/2 -translate-y-1/2 size-10 text-green-500"><use :xlink:href="icon" /></svg>
            </Transition>
        </div>
        <div class="transition-all duration-500 origin-top-left">
            <div class="font-bold leading-[.95em] tracking-tight text-xl"><span ref="mudaTitulo">{{ titulo }}</span></div>
            <div class="text-2xl text-black-40"><span ref="mudaSubtitulo">{{ subtitulo }}</span></div>
        </div>
    </div>
</template>

<style scoped>
.top-icon-enter-active,
.top-icon-leave-active {
  transition: all 0.5s ease;
}

.top-icon-enter-from {
  @apply size-0 rotate-180
}

.top-icon-leave-to {
  @apply size-0 -rotate-180
}
</style>