<script setup>
import {computed, ref, watch} from 'vue'
import { useFormNewStore } from '@/stores/formNew.js'
import { useEventoStore } from '@/stores/eventos.js'
import { useRegistoStore } from '@/stores/registo.js'

import useErrors from '@/use/error';

import StepTitle from '../Final/Blocos/StepTitle.vue'
import Informacoes from '../Final/Blocos/Informacoes.vue'
import FormsWrapper from '../Final/Blocos/FormsWrapper.vue'

import Toast from 'primevue/toast';

const componentMap = {
    Informacoes: Informacoes,
    FormsWrapper: FormsWrapper
};

import NavegacaoFinal from './NavegacaoFinal.vue'
import FormsWrapperFinal from './Blocos/FormsWrapperFinal.vue'
// import SidebarCima from './Blocos/SidebarCima.vue'
// import SidebarBaixo from './Blocos/SidebarBaixo.vue'
// import TextoCima from './Blocos/TextoCima.vue'
// import TextoBaixo from './Blocos/TextoBaixo.vue'


const formNewStore = useFormNewStore()
const eventoStore = useEventoStore()
const registoStore = useRegistoStore()

const errors = useErrors()

const year = new Date().getFullYear()

const props = defineProps({
    calendar: Object,
    eventos: Object,
    dados: Object
})

eventoStore.setCalendar(props.calendar)
eventoStore.setEvents(props.eventos)
formNewStore.fetchUserInfo(props.dados)
registoStore.fetchSessao();
// formNewStore.fakeContent(2)

const step = computed(() => {
    return formNewStore.step
})

const prev = () => {
  console.log('next', formNewStore.stepCurrent)
  formNewStore.stepPrev();
}

const next = () => {
  console.log('next', formNewStore.stepCurrent)
  formNewStore.stepNext();
}

</script>

<template>
    <div>
      <Toast />
  
       <StepTitle :icon="'/storage/img/new-icons.svg?file#' + (formNewStore.mostraSteps ? formNewStore.step.icon : 'carbon-restaurant')" :title="formNewStore.mostraSteps ? formNewStore.step.title : 'Restauração'" :subtitle="formNewStore.mostraSteps ? (formNewStore.stepCurrent + '/' + formNewStore.steps.length) : 'Formulário de Inscrição'"></StepTitle>
      <div class="w-full h-full">
        <Transition name="fade" mode="out-in">
            <div :key="formNewStore.mostraSteps ? formNewStore.stepCurrent : formNewStore.mostraSteps">
                <div class="conteudo conteudo-y pt-0">
                    <div class="relative">
                        <div class="text-sm w-full md:w-3/4 lg:w-2/3 xl:w-1/2 font-medium text-black/50 opacity-0">
                            Agradecemos o seu interesse em participar nos eventos Made Of You. Por favor, leia atentamente as informações abaixo e preencha o formulário de candidatura para que possamos considerá-lo.
                        </div>
                        <div v-if="!formNewStore.mostraSteps" class="absolute top-0 left-0 text-sm w-full md:w-3/4 lg:w-2/3 xl:w-1/2 font-medium text-black/50">
                            Agradecemos o seu interesse em participar nos eventos Made Of You. Por favor, leia atentamente as informações abaixo e preencha o formulário de candidatura para que possamos considerá-lo.
                        </div>
                        <div v-else class="absolute top-0 left-0 text-sm w-full md:w-3/4 lg:w-2/3 xl:w-1/2 font-medium text-black/50" v-html="(formNewStore.step.text ?? '')"></div>
                    </div>
                </div>
            </div>
        </Transition>
        <div class="border-y border-neutral-200 overflow-hidden">
            <Transition name="top-btn" mode="out-in">
                <div :key="formNewStore.mostraSteps ? 'btn-volta' : 'btn-avanca'">
                  <button v-if="formNewStore.step.slug != 'confirmacao'" @click="!formNewStore.mostraSteps ? formNewStore.setStep(0) : formNewStore.stepPrev()" class="conteudo py-6 hover:bg-neutral-50 flex items-center space-x-4 text-black" :class="{'opacity-50 cursor-default': formNewStore.processingForm, 'justify-end': !formNewStore.mostraSteps}">
                    <span v-if="!formNewStore.mostraSteps" class="text-lg font-black" v-html="'Iniciar Formulário'"></span>
                    <svg v-if="formNewStore.processingForm" class="size-6"><use class="animate-spin origin-center" xlink:href="/storage/img/new-icons.svg?file#carbon-rotate-180" /></svg>
                    <svg class="size-6" v-else><use :xlink:href="'/storage/img/new-icons.svg?file#carbon-arrow-' + (!formNewStore.mostraSteps ? 'right' : 'left')" /></svg>
                    <span v-if="formNewStore.mostraSteps" class="text-lg font-black" v-html="'Voltar'"></span>
                </button>
              </div>
            </Transition>
        </div>

        <Transition name="fade" mode="out-in">
            <component :key="formNewStore.mostraSteps ? 'forms-wrapper' : 'informacoes'" :is="formNewStore.mostraSteps ? componentMap.FormsWrapper : componentMap.Informacoes"></component>
        </Transition>
      </div>
    </div>
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from {
  @apply opacity-0 translate-x-full
}

.fade-leave-to {
  @apply delay-150 opacity-0 -translate-x-full
}

.top-btn-enter-active,
.top-btn-leave-active {
  transition: all 0.5s ease;
}

.top-btn-enter-from {
  @apply translate-y-full
}

.top-btn-leave-to {
  @apply -translate-y-full
}
</style>