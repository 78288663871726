<script setup>
import { watch, ref, onMounted, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useFormStore } from '@/stores/form'
import { useEventoStore } from '@/stores/eventos'

import useErrors from '@/use/error';
const formStore = useFormStore()
const errors = useErrors()





import Intro from '../Steps/Intro.vue'
import Eventos from '../Steps/Eventos.vue'
import InfoEmpresa from '../Steps/InfoEmpresa.vue'
import InfoFoodtruck from '../Steps/InfoFoodtruck.vue'
import Documentos from '../Steps/Documentos.vue'
import Confirmacao from '../Steps/Confirmacao.vue'

const componentMap = {
    Intro: Intro,
    Eventos: Eventos,
    InfoEmpresa: InfoEmpresa,
    InfoFoodtruck: InfoFoodtruck,
    Documentos: Documentos,
    Confirmacao: Confirmacao
};

const transitionName = ref('slide-left')

const beforeEnter = (el) => {
      el.style.transform = transitionName.value === 'slide-left' ? 'translateX(-100%)' : 'translateX(100%)';
}
const enter = (el, done) => {
    setTimeout(() => {
    el.style.transition = 'transform 0.5s';
    el.style.transform = 'translateX(0)';
    }, 50);
    done();
}
const leave = (el, done) => {
    el.style.transition = 'transform 0.5s';
    el.style.transform = transitionName.value === 'slide-left' ? 'translateX(100%)' : 'translateX(-100%)';
    setTimeout(done, 500);
}

// watch: {
//     currentId(newValue, oldValue) {
//       transitionName.value = newValue > oldValue ? 'slide-left' : 'slide-right';
//     }
//   }

  const { stepCurrent } = storeToRefs(formStore)

  watch(stepCurrent, (newValue, oldValue) => {
    transitionName.value = newValue > oldValue ? 'slide-right' : 'slide-left';
    console.log('isLoggedIn ref changed, do something!', newValue, oldValue)
  })

  const step = computed(() => {
    return formStore.step
})

  const preenchidos = computed(() => {
    if(formStore.step.slug == 'introducao' || formStore.step.slug == 'confirmacao'){
        return true
    } else if(formStore.step.slug == 'eventos-e-sessoes'){
        if(formStore.formdata.eventos.length > 0){
            return true
        }
    } else {
        if(formStore.step.fields.filter((field) => field.type != 'radio' && !field.valor).length == 0){
            return true
        }
    }
    return false
});

  const valida = () => {

    if(preenchidos){
        formStore.stepNext()
    }
        // if(formStore.step.slug == 'eventos' &&  formStore.formdata.eventos.length > 0){
        //     formStore.stepNext()
        // }

        // if(formStore.step.slug == 'informacoes-da-empresa' &&  formStore.formdata.eventos.length > 0){
        //     formStore.stepNext()
        // }

        // var campos = step.fields.filter((field) => field.valor)
        // console.log(campos.length)
  }

</script>

<template>
    

    <!-- <div class=""> -->
    <div class="form-wrapper absolute overflow-y-auto">
        
        
        <!-- <transition 
    :name="transitionName" 
    @before-enter="beforeEnter" 
    @enter="enter" 
    @leave="leave"
  > -->
        <transition name="step" mode="out-in">
            <div class="w-full h-full " :key="formStore.step.component">
                <!-- <PerfectScrollbar> -->
                    <div class="conteudo h-full min-h-full lg:min-h-[100dvh] grid lg:grid-cols-3 2xl:grid-cols-2">
                        <div class="lg:col-start-2 lg:col-span-2 xl:col-start-2 2xl:col-start-2 2xl:col-span-1 w-full h-full flex flex-col justify-end">
                            <div class="conteudo-y pb-0">
                                <div
                                    class="relaive border border-white/50 rounded-t-3xl bg-white/30 backdrop-blur pb-24 divide-y divide-white/50">
                                    <div class="p-6">
                                        <div class="flex items-center space-x-4">
                                            <div class="relative size-20 flex items-center justify-center rounded-full border border-white/50">
                                                <div class="absolute top-0 left-0 size-20 rounded-full backdrop-contrast-50"></div>
                                                <div class="absolute top-0 left-0 size-20 rounded-full backdrop-contrast-50"></div>
                                                <!-- <div class="absolute top-0 left-0 size-20 rounded-full backdrop-brightness-150"></div> -->
                                                <svg class="relative size-12 text-black">
                                                    <use :xlink:href="'/storage/img/new-icons.svg?file#' + formStore.step.icon ?? ''" />
                                                </svg>
                                            </div>
                                            <div>
                                                <div>{{formStore.stepCurrent }}<span v-if="formStore.steps">/ {{formStore.steps.length - 1 }}</span></div>
                                                <div class="text-2xl font-bold" :class="{'text-red-500': formStore.step.invalid}" v-html="(formStore.step.title ?? '')"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <button v-if="formStore.step.slug != 'confirmacao' && formStore.step.slug != 'introducao'" @click="formStore.processingForm ? '' : formStore.stepPrev()" class="w-full mt-6 py-3 px-6 flex items-center space-x-4 hover:bg-white/50" :class="{'opacity-50 cursor-default': formStore.processingForm}">
                                        <svg v-if="formStore.processingForm" class="size-8 text-black"><use class="animate-spin origin-center" xlink:href="/storage/img/new-icons.svg?file#carbon-rotate-180" /></svg>
                                        <svg class="size-8 text-black" v-else><use xlink:href="/storage/img/new-icons.svg?file#carbon-arrow-left" /></svg>
                                        <span class="font-black uppercase">Voltar</span>
                                    </button>
                                    
                                    <div class="p-6">
                                        <div class="flex space-x-3">
                                            <div class="text-xl font-medium" v-html="(formStore.step.text ?? '')">
                                            </div>
                                        </div>
                                    </div>
                                    <component :is="componentMap[formStore.step.component]"></component>
                                    <!-- <button @click="formStore.stepNext" class="w-full p-6 bg-white flex justify-end items-center space-x-4">
                                        <span class="text-xl font-black uppercase">Avançar</span>
                                        <svg class="size-12 text-black"><use xlink:href="/storage/img/new-icons.svg?file#carbon-arrow-right" /></svg>
                                    </button> -->

                                    <button v-if="formStore.step.slug != 'confirmacao'" @click="!preenchidos || formStore.processingForm ? '' : formStore.stepNext()" class="w-full mt-6 p-6 bg-white flex justify-end items-center space-x-4" :class="{'opacity-50 cursor-default': !preenchidos || formStore.processingForm}">
                                        <span class="text-xl font-black uppercase">Avançar</span>
                                        <svg v-if="formStore.processingForm" class="size-12 text-black"><use class="animate-spin origin-center" xlink:href="/storage/img/new-icons.svg?file#carbon-rotate-180" /></svg>
                                        <svg class="size-12 text-black" v-else><use xlink:href="/storage/img/new-icons.svg?file#carbon-arrow-right" /></svg>
                                    </button>
                                    <!-- <pre>{{  formStore.step.slug  }} - {{  preenchidos  }}</pre> -->
                                    <div v-if="!preenchidos" class="text-sm font-bold p-6">Todos os campos são de preenchimento obrigatório.</div>
                                </div>
                            </div>
                        </div>
                    </div>

                <!-- </PerfectScrollbar> -->
            </div>
        </transition>
        </div>
    <!-- </div> -->
</template>

<style>
.form-wrapper .ps {
    @apply w-full h-full overflow-y-auto
  }
.form-wrapper {
    .input-wrapper {
        @apply mb-6;
        .input-text__label {
            @apply block mb-2 text-sm font-bold text-neutral-700;
        }
        .input-text__input {
            @apply bg-neutral-50 border border-neutral-500 text-neutral-900 placeholder-neutral-700 text-sm rounded-lg focus:ring-neutral-500 focus:border-neutral-500 block w-full p-2.5;
        }
        .input-text__mensagem {
            @apply mt-2 text-sm text-neutral-600;
        }

        &.input--success {
            .input-text__label {
                @apply text-green-700;
            }
            .input-text__input {
                @apply bg-green-50 border-green-500 text-green-900 placeholder-green-700 focus:ring-green-500 focus:border-green-500;
            }
            .input-text__mensagem {
                @apply text-green-600;
            }
        }
        &.input--error {
            .input-text__label {
                @apply text-red-700;
            }
            .input-text__input {
                @apply bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500;
            }
            .input-text__mensagem {
                @apply text-red-600;
            }
        }
    }

    
}
</style>
