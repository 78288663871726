<script setup>
import {computed, ref, watch} from 'vue'
import { useFormStore } from '@/stores/form.js'
import { useEventoStore } from '@/stores/eventos.js'
import ShuffleText from 'shuffle-text';

import useErrors from '@/use/error';

import Navegacao from './Navegacao.vue'
import FormsWrapper from './Blocos/FormsWrapper.vue'
import SidebarCima from './Blocos/SidebarCima.vue'
import SidebarBaixo from './Blocos/SidebarBaixo.vue'
import TextoCima from './Blocos/TextoCima.vue'
import TextoBaixo from './Blocos/TextoBaixo.vue'

const formStore = useFormStore()
const eventoStore = useEventoStore()

const errors = useErrors()

const year = new Date().getFullYear()

const props = defineProps({
    calendar: Object,
    eventos: Object
})

eventoStore.setCalendar(props.calendar)
eventoStore.setEvents(props.eventos)
formStore.fakeContent(2)

const step = computed(() => {
    return formStore.step
})

const prev = () => {
  console.log('next', formStore.stepCurrent)
  formStore.stepPrev();
}

const next = () => {
  console.log('next', formStore.stepCurrent)
  formStore.stepNext();
}
const titulo = formStore.step.title


const mudaTitulo = ref(null)

watch(() => formStore.stepCurrent, (newTitle) => {
    mudaTitulo.value.innerText = formStore.step.title;
    var text = new ShuffleText(mudaTitulo.value);
    text.start();
});
</script>
<script>
  export default {
    name: 'MainWrapperNew'
  }
</script>

<template>
    <div id="MainWrapperNew">
        <div v-if="1<2" class="fixed w-[100dvw] overflow-hidden">

            <div class="w-full min-h-[100dvh] h-full grid lg:grid-cols-2">
                <!-- <div class="absolute w-full h-10 bg-red-500"></div> -->
                <div id="MainWrapperNew--infos" class="relative w-full h-full flex flex-col justify-between">
                    <div class="relative">
                        <div class="conteudo conteudo-y">
                            <div>
                                <img class="relative size-10 mb-4 transition-all duration-500 "
                                    :class="{'opacity-50 scale-75 mb-3': formStore.mostraSteps}"
                                    src="/storage/img/moyg-eye.svg" alt="">
                                <div class="transition-all duration-500"
                                    :class="{'scale-50': formStore.mostraSteps}">
                                    <div class="font-bold leading-[.95em] tracking-tight text-4xl">Food Trucks</div>
                                    <div class="text-5xl text-black-40 transition-all duration-500 "
                                        :class="{'opacity-50': formStore.mostraSteps}">Formulário de Inscrição</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="relative">
                        <div class="absolute top-0 w-[100dvw] -translate-x-0 h-0.5 bg-white/50 transition-all duration-500"
                            :class="{'!-translate-x-[200dvw]': formStore.mostraSteps}"></div>
                    </div>
                    <div class="conteudo conteudo-y">
                        <div
                            class="relative w-[100dvw] h-100dvhl flex flex-col justify-between space-y-4 overflow-hiden">
                            <!-- <div class="w-3/4 text-base font-medium" v-html="step.text"></div> -->
                            <div class="text-7xl font-extralight">{{formStore.stepCurrent }}<span v-if="formStore.steps"
                                    class="text-4xl align-bottom">/ {{formStore.steps.length }}</span></div>
                            <div ref="mudaTitulo" class="text-7xl font-black">{{ titulo }}</div>
                            <div class="text-xs opacity-50"><strong>©{{ year }} Made of You Group</strong><br>All rights
                                reserved</div>
                        </div>
                    </div>
                </div>
            </div>




            <div class="fixed inset-0">
                <transition name="forms-wrapper">
                    <div v-if="formStore.mostraSteps">
                        <FormsWrapper></FormsWrapper>
                    </div>
                </transition>

                <!-- <FormsWrapper></FormsWrapper> -->
                </div>

                        <navegacao></navegacao>
            </div>
        <div class="fixed top-6 right-6">
                            <div class="rotate-90 origin-top-right">
                                <div class="-translate-y-1/2 translate-x-full flex items-center space-x-2">
                                    <div class="text-xs font-bold">MOY</div>
                                    <div class="w-16 h-0.5 bg-black/30"></div>
                                    <div class="text-xs font-bold">GROUP</div>
                                </div>
                            </div>
                        </div>
        
    </div>
</template>
<style scoped>
.linha-guia {
    @apply border border-red-500/0
}
.tapa {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%)
}
</style>