<template>
    <div>
    <!-- <PerfectScrollbar> -->
        <div class="relative z-50conteudo py-36">
            <div class="grid grid-cols-12 gap-8">
                <div class="col-span-9 text-7xl">Formulário de Inscrição <strong>Food Trucks</strong></div>
                <div class="col-span-9 text-xl">Agradecemos o seu interesse em participar nos eventos Made Of You. Por favor, leia atentamente as informações abaixo e preencha o formulário de candidatura para que possamos considerá-lo.<br><br>Estamos entusiasmados para analisar a sua inscrição. Se for selecionado, entraremos em contato diretamente por e-mail.<br><br>Na próxima página, encontrará todas as informações necessárias para concluir a sua inscrição.</div>
                <div class="col-span-9 rounded-3xl p-8 bg-verde-500/20">
                    <div class="grid grid-cols-3 gap-8">
                        <div>
                            <div class="font-bold uppercase text-xs pb-1 text-black border-b border-black">Processo de<br>Inscrição</div>
                            <div class="text-sm mt-6">Esteja ciente de que o preenchimento deste formulário não garante automaticamente a sua participação. Entraremos em contato diretamente caso a sua candidatura seja selecionada para avançar para a próxima fase.</div>
                        </div>
                        <div>
                            <div class="font-bold uppercase text-xs pb-1 text-black border-b border-black">Taxas de<br>Participação</div>
                            <div class="text-sm mt-6">Para validar a inscrição, é necessário o pagamento de uma taxa de participação. Esta taxa é fixa e não negociável. Os candidatos selecionados serão informados sobre os procedimentos de pagamento. Cada evento tem uma taxa de inscrição diferente.</div>
                        </div>
                        <div>
                            <div class="font-bold uppercase text-xs pb-1 text-black border-b border-black">Política de<br>Pagamentos</div>
                            <div class="text-sm mt-6">Todas as transações devem ser realizadas exclusivamente através do sistema do evento. Qualquer tentativa de utilizar outros métodos de pagamento resultará na rescisão imediata da inscrição e inclusão na lista negra do evento.</div>
                        </div>
                    </div>
                </div>
                <div class="col-span-full flex justify-end">
                    <button @click="primeiro" class="relative w-full inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden text-white font-medium bg-verde-500 transition duration-300 ease-out border-2 border-verde-500 rounded-full shadow-md group">
    <span class="absolute inset-0 flex items-center justify-center w-full h-full bg-white duration-300 -translate-y-full text-verde-500 group-hover:translate-y-0 ease">
    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
    </span>
    <span class="font-bold absolute flex items-center justify-center w-full h-full text-white transition-all duration-300 transform group-hover:translate-y-full ease">Submeter</span>
    <span class="relative invisible">Submeter</span>
    </button>
                </div>
            </div>
        </div>
    <!-- </Per
        fectScrollbar> -->
        <div class="grid grid-cols-4 w-full h-full">
            <div class="col-span-2 flex items-center">
            <div class="rounded-3xl border-2  border-white">
                <div class="font-black">PROCESSO DE INSCRIÇÃO</div>
                <div class="text-sm">Esteja ciente de que o preenchimento deste formulário não garante automaticamente a sua participação. Entraremos em contato diretamente caso a sua candidatura seja selecionada para avançar para a próxima fase.</div>
            </div>
            <div class="rounded-3xl border-2  border-white">
                <div class="font-black">TAXAS DE PARTICIPAÇÃO</div>
                <div class="text-sm">Para validar a inscrição, é necessário o pagamento de uma taxa de participação. Esta taxa é fixa e não negociável. Os candidatos selecionados serão informados sobre os procedimentos de pagamento. Cada evento tem uma taxa de inscrição diferente.</div>
            </div>
            <div class="rounded-3xl border-2  border-white">
                <div class="font-black">POLÍTICA DE PAGAMENTOS</div>
                <div class="text-sm">Todas as transações devem ser realizadas exclusivamente através do sistema do evento. Qualquer tentativa de utilizar outros métodos de pagamento resultará na rescisão imediata da inscrição e inclusão na lista negra do evento.</div>
            </div>
            <div class="rounded-3xl border-2  border-white">
                <div class="font-black">Estamos entusiasmados para analisar a sua inscrição. Se for selecionado, entraremos em contato diretamente por e-mail. Na próxima página, encontrará todas as informações necessárias para concluir a sua inscrição.</div><div class="text-sm">Estamos entusiasmados para analisar a sua inscrição. Se for selecionado, entraremos em contato diretamente por e-mail. Na próxima página, encontrará todas as informações necessárias para concluir a sua inscrição.</div>
            </div>
        </div>
        </div>
</div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
const formStore = useFormStore()



const primeiro = () => {
    // if(formStore.unlocked < 1) formStore.unlocked = 1;
    // formStore.stepCurrent = 1;

    formStore.setStep(1)
}
</script>

<style scoped>
  .ps {
    @apply w-full h-full overflow-y-auto
  }
</style>
