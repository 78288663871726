<script setup>
import { useFormNewStore } from '@/stores/formNew.js'
const formNewStore = useFormNewStore();
</script>

<template>
    <button v-if="formNewStore.step.slug != 'confirmacao'" @click="formNewStore.step.valid && !formNewStore.processingForm ? formNewStore.stepNext() : ''" class="conteudo py-6 border-y border-neutral-200 hover:bg-neutral-50 flex items-center justify-end space-x-4 text-black" :class="{'hover:!bg-red-50 text-opacity-20 cursor-default': !formNewStore.step.valid || formNewStore.processingForm}">
            <span class="text-lg font-black">Avançar</span>
            <svg v-if="formNewStore.processingForm" class="size-10"><use class="animate-spin origin-center" xlink:href="/storage/img/new-icons.svg?file#carbon-rotate-180" /></svg>
            <svg class="size-10" v-else><use xlink:href="/storage/img/new-icons.svg?file#carbon-arrow-right" /></svg>
        </button>
</template>
<style scoped>
</style>