<script setup>
import { useFormNewStore } from '@/stores/formNew.js'
const formNewStore = useFormNewStore()

import IntroGrid from './IntroGrid.vue'
</script>

<template>
    <div>
        <!-- <div class="flex justify-end conteudo conteudo-y !conteudo-mini-t">
            <button @click="formNewStore.setStep(0)" class="relative inline-flex items-center justify-center my-6 p-4 px-6 py-3 overflow-hidden text-white font-medium bg-green-500 transition duration-300 ease-out border-2 border-green-500 rounded-full shadow-md group">
                <span class="absolute inset-0 flex items-center justify-center w-full h-full bg-white duration-300 -translate-y-full text-green-500 group-hover:translate-y-0 ease">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                </span>
                <span class="font-bold absolute flex items-center justify-center w-full h-full text-white transition-all duration-300 transform group-hover:translate-y-full ease">Iniciar Formulário</span>
                <span class="relative invisible">Iniciar Formulário</span>
            </button>
        </div> -->
        <div class="grid grid-cols-1 conteudo conteudo-mini-y conteudo-gap-mini bg-neutral-100">
            <IntroGrid icon="/storage/img/new-icons.svg?file#carbon-license-third-party-draft" title="Processo de<br>Inscrição" text="Esteja ciente de que o preenchimento deste formulário não garante automaticamente a sua participação. Entraremos em contato diretamente caso a sua candidatura seja selecionada para avançar para a próxima fase."></IntroGrid>
            <IntroGrid icon="/storage/img/new-icons.svg?file#carbon-money" title="Taxas de<br>Participação" text="Para validar a inscrição, é necessário o pagamento de uma taxa de participação. Esta taxa é fixa e não negociável. Os candidatos selecionados serão informados sobre os procedimentos de pagamento. Cada evento tem uma taxa de inscrição diferente."></IntroGrid>
            <IntroGrid icon="/storage/img/new-icons.svg?file#carbon-ibm-watson-knowledge-catalog" title="Política de<br>Pagamentos" text="Todas as transações devem ser realizadas exclusivamente através do sistema do evento. Qualquer tentativa de utilizar outros métodos de pagamento resultará na rescisão imediata da inscrição e inclusão na lista negra do evento."></IntroGrid>
        </div>
        <div class="conteudo py-6 border-y border-neutral-200 font-bold">
            <div class="w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                Estamos entusiasmados para analisar a sua inscrição. Se for selecionado, entraremos em contato diretamente por e-mail.
            </div>
        </div>
        <div class="flex justify-end conteudo conteudo-y !conteudo-mini-t">
            <button @click="formNewStore.setStep(0)" class="relative inline-flex items-center justify-center my-6 p-4 px-6 py-3 overflow-hidden text-white font-medium bg-green-500 transition duration-300 ease-out border-2 border-green-500 rounded-full shadow-md group">
                <span class="absolute inset-0 flex items-center justify-center w-full h-full bg-white duration-300 -translate-y-full text-green-500 group-hover:translate-y-0 ease">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                </span>
                <span class="font-bold absolute flex items-center justify-center w-full h-full text-white transition-all duration-300 transform group-hover:translate-y-full ease">Iniciar Formulário</span>
                <span class="relative invisible">Iniciar Formulário</span>
            </button>
        </div>
    </div>
</template>
<style scoped>
</style>