<script setup>
import { ref, onMounted, computed } from 'vue'
import { useFormNewStore } from '@/stores/formNew.js'
const formNewStore = useFormNewStore()

import TextInput from '@/Last/Form/TextInput.vue'
import Checkbox from '@/Last/Form/Checkbox.vue'

import DropzoneInput from '@/Last/Form/DropzoneInput.vue'

const grupos = ref([
    {
        capitulo: '2.1',
        title: 'Dimensões',
        icon: 'carbon-rular-alt'
    },
    {
        capitulo: '2.2',
        title: 'Características Técnicas',
        icon: 'carbon-list-checked'
    },
    {
        capitulo: '2.3',
        title: 'Cozinha',
        icon: 'carbon-restaurant'
    },
    {
        capitulo: '2.4',
        title: 'Fotos',
        icon: 'carbon-image-copy'
    }
])


const pronto = ref(false)
onMounted(() => {
    pronto.value = true
});

const toggleSimNao = (slug) => {
    var field = formNewStore.step.fields.filter((field) => field.slug == slug)
    
    if(field.valor == 'Não') {
        field.valor = 'Sim'
    } else {
        field.valor = 'Não'
    }
}

formNewStore.step.mensagemErro = computed(() => {
    if(!formNewStore.step.valid) return 'Todos os campos marcados com (*) são obrigatórios.'
    return false
});

formNewStore.step.valid = computed(() => {
    var campos = formNewStore.step.fields.filter((field) => !field.valid)
    var docs = formNewStore.menu.id && formNewStore.fotos.length > 0
    return campos.length > 0 || !docs ? false : true
});
</script>

<template>
    <div class="conteudo-mini">
        <div class="grid grid-cols-1 conteudo-gap-mini">
            <div v-for="(grupo, groupIndex) in grupos" class="nm-flat-neutral-100 rounded-2xl overflow-hidden">
                <div class="conteudo-mini pb-4 flex flex-col justify-between border-b border-neutral-300">
                    <div class="flex items-center space-x-3 text-green-500">
                        <svg class="size-8"><use :xlink:href="'/storage/img/new-icons.svg?file#' + grupo.icon" /></svg>
                        <div class="font-bold uppercase" v-html="grupo.capitulo + ' ' + grupo.title"></div>
                    </div>
                    <div v-if="grupo.text" class="text-sm mt-4" v-html="grupo.text"></div>
                </div>
                <div class="conteudo-mini bg-white grid grid-cols-6 conteudo-gap-mini-x gap-y-6">
                    <div v-for="field in formNewStore.foodtruck.filter((item) => item.group == groupIndex)" class="col-span-full" :class="{'md:col-span-3': ['comprimento', 'largura', 'altura', 'peso'].includes(field.slug), 'md:col-span-2': field.type == 'radio'}">
                        <div v-if="field.type == 'file'">
                            <DropzoneInput :key="'field-'+field.id" :field="field"></DropzoneInput>
                        </div>
                        <div v-else-if="field.type != 'radio'" class="input-wrapper"
                            :class="{'input--success': 1>2, 'input--error': (formNewStore.erros && formNewStore.erros[field.slug]) || !field.valor }">
                            <TextInput :field="field"></TextInput>
                        </div>
                        <div v-else>
                            <Checkbox :field="field"></Checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>