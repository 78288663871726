<script setup>
import { ref, onMounted, computed } from 'vue'
const formStore = useFormStore()


const props = defineProps({
    calendar: Object,
    eventos: Object
})

eventoStore.setCalendar(props.calendar)
eventoStore.setEvents(props.eventos)

// formStore.fakeContent()

import CircularProgress from './CircularProgress.vue'

import Intro from './Steps/Intro.vue'
import Eventos from './Steps/Eventos.vue'
import InfoEmpresa from '../Mine/Steps/InfoEmpresa.vue'
import InfoFoodtruck from './Steps/InfoFoodtruck.vue'
import Documentos from './Steps/Documentos.vue'
import Confirmacao from '../Mine/Steps/Confirmacao.vue'

const componentMap = {
    Intro: Intro,
    Eventos: Eventos,
    InfoEmpresa: InfoEmpresa,
    InfoFoodtruck: InfoFoodtruck,
    Documentos: Documentos,
    Confirmacao: Confirmacao
};
</script>

<template>
    <!-- <div class="relative top-0 left-0 bottom-0 right-0 transition-all duration-500"> -->
                
                <!-- <div class="absolute inset-0 overflow-hidden"> -->
                        
                        <!-- <PerfectScrollbar> -->
                            <!-- <transition name="step" mode="out-in"> -->
            <!-- <component :is="componentMap[formStore.step.component]" :key="formStore.step.component"></component> -->
        <!-- </transition> -->
    <!-- </PerfectScrollbar> -->
                        <div class="absolute top-6 right-6">
                            <div class="rotate-90 origin-top-right">
                                <div class="-translate-y-1/2 translate-x-full flex items-center space-x-2">
                                    <div class="text-xs font-bold">MOY</div>
                                    <div class="w-16 h-0.5 bg-black/30"></div>
                                    <div class="text-xs font-bold">GROUP</div>
                                </div>
                            </div>
                        </div>
                    <!-- </div> -->
                <!-- </div> -->
</template>

<style scoped>
/* .ps {
    @apply w-full h-[100dvh] overflow-y-auto
  } */


</style>
