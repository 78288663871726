<script setup>
import { ref, onMounted, computed } from 'vue'
import { useFormNewStore } from '@/stores/formNew.js'
const formNewStore = useFormNewStore()

import TextInput from '@/Last/Form/TextInput.vue'

const grupos = ref([
    {
        capitulo: '1.1',
        title: 'Empresa',
        icon: 'carbon-document-attachment'
    },
    {
        capitulo: '1.2',
        title: 'Responsável',
        icon: 'carbon-user-identification'
    },
    {
        capitulo: '1.3',
        title: 'Presença Online',
        icon: 'carbon-content-delivery-network',
        text: 'Por favor, ajude-nos a encontrá-lo online. Embora esses campos não sejam obrigatórios, eles facilitam o nosso processo de seleção.'
    }
])

const preenchidos = computed(() => {
    // return formNewStore.pessoais.filter((field) => field.required && !field.valor)
    var campos = formNewStore.pessoais.filter((field) => !field.valid && field.type != 'email')
    return campos.length === 0 ? true : false
});

formNewStore.step.mensagemErro = computed(() => {
    var obrigatorios = formNewStore.step.fields.filter((field) => field.errorMessage == 'Este campo é obrigatório.' || (field.required && !field.valor))
    if(obrigatorios.length > 0) return 'Todos os campos marcados com (*) são obrigatórios.'


    var outros = formNewStore.step.fields.filter((field) => field.errorMessage)
    if(outros.length > 0) return outros[0].errorMessage
    
    return false
});
formNewStore.step.valid = computed(() => {
    var campos = formNewStore.step.fields.filter((field) => !field.valid)
    return campos.length > 0 ? false : true
});
</script>

<template>
    <div class="conteudo-mini">
        <div class="grid grid-cols-1 conteudo-gap-mini">
            <div v-for="(grupo, groupIndex) in grupos" class="nm-flat-neutral-100 rounded-2xl overflow-hidden">
                <div class="conteudo-mini pb-4 flex flex-col justify-between border-b border-neutral-300">
                    <div class="flex items-center space-x-3 text-green-500">
                        <svg class="size-8"><use :xlink:href="'/storage/img/new-icons.svg?file#' + grupo.icon" /></svg>
                        <div class="font-bold uppercase" v-html="grupo.capitulo + ' ' + grupo.title"></div>
                    </div>
                    <div v-if="grupo.text" class="text-sm mt-4" v-html="grupo.text"></div>
                </div>
                <div class="conteudo-mini bg-white grid grid-cols-2 conteudo-gap-mini-x gap-y-6">
                    <div v-for="field in formNewStore.pessoais.filter((item) => item.group == groupIndex)" class="col-span-full" :class="{'md:col-span-1': ['cae', 'nif'].includes(field.slug)}">
                        <TextInput :field="field"></TextInput>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
  .text-input {
    @apply block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-green-600
  }
.text-label {
    @apply absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6
}

.area-icon__wrapper {
    @apply flex size-16 shrink-0 items-center justify-center rounded-full bg-green-500/10;
    .area-icon {
    @apply size-8 text-green-500;
    }
}

  .invalid {
    input,
    .text-input {
        @apply text-red-500 border-red-500 focus:border-red-500
    }
    label,
    .text-label {
        @apply text-red-500 peer-focus:text-red-500
    }

    .area-icon__wrapper {
    @apply bg-red-500/10;
    .area-icon {
    @apply text-red-500;
    }
    }
  }
</style>
