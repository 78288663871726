<script setup>
import { ref, onMounted, computed } from 'vue'
const formStore = useFormStore()

import useErrors from '@/use/error';

const errors = useErrors()

const year = new Date().getFullYear()

const step = computed(() => {
    return formStore.step
})
</script>

<template>
    <div>
        <transition name="sidebar">
            <div v-if="formStore.stepCurrent > 0" class="fixed top-0 left-0 w-1/4 h-full">
                <div class="absolute inset-0 rounded-3xl conteudo-mini bg-black/70 overflow-hidden">
                <!-- <PerfectScrollbar> -->
                    
                    <div class="min-h-full flex flex-col justify-between">
                    <div class="flex flex-col space-y-6">
                        <img class="relative size-6" src="/storage/img/moyg-eye.svg" alt="">
                        <div class="font-bold uppercase leading-[.95em] tracking-tight text-xs text-verde-900">Inscrição<br>Foodtrucks</div>
                        <div class="font-bold leading-[.95em] tracking-tight text-3xl text-black" v-html="step.title"></div>
                        <div class="text-sm font-medium text-black" v-html="step.text"></div>
                        <div v-if="step.atencao" class="relative">
                            <span class="flex absolute h-3 w-3 top-1 left-1 -mt-1 -mr-1">
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-verde-400 opacity-75"></span>
      <span class="relative inline-flex rounded-full h-3 w-3 bg-verde-500"></span>
    </span>
                            <div class="text-sm font-medium text-black mt-5" v-html="step.atencao"></div>
                        </div>
                    </div>
                            <!-- <div>
                                <pre class="text-black">formStore.errors<br>{{ formStore.errors }}</pre>
                                <pre class="text-black">formStore.documentosFormdata<br>{{ formStore.documentosFormdata }}</pre>
                                <pre class="text-black">formStore.documentos<br>{{ formStore.documentos }}</pre>
                                <pre class="text-black">formStore.formdata<br>{{ formStore.formdata }}</pre>
                                <pre class="text-black">formStore.pessoais<br>{{ formStore.pessoais }}</pre>
                        </div> -->
                    <div class="flex flex-col">
                        <div v-for="stepItem in formStore.steps" class="flex items-center space-x-3">
                            <div class="size-8 flex items-center justify-center text-black/30" :class="{
                                'bg-verde-500 !text-white/100': stepItem.index <= formStore.stepCurrent,
                                'rounded-t-full': stepItem.index == 0,
                                'rounded-b-full': stepItem.index == formStore.stepCurrent
                            }">
                                <svg class="size-4"><use :xlink:href="'/storage/img/new-icons.svg?file#' + stepItem.icon" /></svg>
                            </div>
                            <div class="text-xs text-black/30" :class="{'!text-black/100': stepItem.index <= formStore.stepCurrent, 'font-bold': stepItem.index == formStore.step.index}" v-html="stepItem.title"></div>
                        </div>
                    </div>
                    <div class="text-xs"><strong>©{{ year }} Made of You Group</strong><br>All rights reserved</div>
                </div>
                <!-- </PerfectScrollbar> -->
                </div>
            </div>
        </transition>
    </div>
</template>
<style scoped>
/* .ps {
    @apply w-full h-full overflow-y-auto
  } */


</style>