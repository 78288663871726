<script setup>
const props = defineProps({
    tipo: {
        type: String,
        default: 'warning'
    },
    text: String
})

const infos = {
    warning: {
        icon: '/storage/img/new-icons.svg?file#carbon-warning',
        color: 'text-red-500',
        bg: 'bg-red-50',
        iconBg: 'bg-red-500'
    }
}
</script>

<template>
    <div class="conteudo py-6 text-sm font-medium flex items-center space-x-4" :class="infos[tipo].bg + ' ' + infos[tipo].color">
        <div class="size-10 rounded-lg flex items-center justify-center shadow-lg" :class="infos[tipo].iconBg">
            <svg class="size-5 text-white"><use :xlink:href="infos[tipo].icon" /></svg>
        </div>
        <div v-html="text"></div>
    </div>
</template>
<style scoped>
</style>