<script lang="ts" setup>
import { toRefs } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
  modelValue: {
    type: null,
  },
  name: String,
  value: {
    type: null,
  },
  sessao: Object,
});

// we are using toRefs to create reactive references to `name` and `value` props
// this is important because vee-validte needs to know if any of these props change
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const { name } = toRefs(props);
const { checked, handleChange } = useField(name, undefined, {
  type: 'checkbox',
  checkedValue: props.value,
});
</script>

<template>
  <div>
    <button type="button" @click="handleChange(value)"
      class="conteudo-mini py-4 w-full flex items-center space-x-4 bg-white/ hover:bg-neutral-100 border-b border-neutral-200"
      :class="{'!bg-green-500 !text-white shadow-lg': checked}">
      <div class="flex-none relative size-6 border text-white  border-black/30 bg-white/0 rounded-full flex items-center justify-center transition-all duration-500"
          :class="{'!text-white !bg-green-500 border-green-500': checked}">
          <transition name="check" mode="out-in">
              <svg v-if="checked"
                  class="check-icon size-6">
                  <use xlink:href="/storage/img/new-icons.svg?file#carbon-checkmark-filled" /></svg>
          </transition>
      </div>
      <div class="grow text-left font-bold text-sm transition-all duration-500"
          :class="{'!uppercase !text-white': checked}">
          {{ sessao.date }}</div>

  </button>
  </div>
</template>

<style scoped>
button {
  background: none;
  border: none;
  font-size: 22px;
}
</style>
