<template>
    <div class="p-6">
        <div class="min-h-full flex flex-col justify-between">
            <div class="grid grid-cols-1 gap-6">
                <div v-for="field in formStore.documentos">
                    <div  v-if="field.type == 'file'"  class="input-wrapper">
                        <label :for="field.slug" class="input-text__label">{{field.title }} *</label>
                        <dropzone-input :name="field.slug" :multiple="0"></dropzone-input>
                    </div>
                </div>
                <!-- <button @click="valida" class="w-full h-full bg-verde-500 hover:bg-verde-600">Submeter</button> -->
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useFormStore } from '@/stores/form.js'
const formStore = useFormStore()

    import DropzoneInput from '../DropzoneInput.vue'

const pronto = ref(false)
onMounted(() => {
    pronto.value = true
});
</script>

<style scoped>
  .info-foodtruck--truck-enter-active,
  .info-foodtruck--truck-leave-active {
    @apply transition-all duration-[4s] ease-out;
  }
  .info-foodtruck--truck-enter-from {
    @apply top-0 left-[100dvw]
  }
  .info-foodtruck--truck-leave-to {
    @apply top-[112dvh] left-[-100dvw]
  }
</style>
