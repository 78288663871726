<script>
    // import { nextTick } from 'vue';
    import { useWindowScroll } from '@vueuse/core'

    
    import AppWrapper from '@/Last/AppWrapper.vue'

    
    import Clock from './Mine/Clock.vue'
    import Eye from './Mine/Eye.vue'
    import Olho from './Mine/Olho.vue'
    // import DropzoneInput from './Mine/DropzoneInput.vue'

    
    
    
    import FormSteps from './Mine/FormSteps.vue'

    import SideInfos from './New/SideInfos.vue'
    import Navegacao from './New/Navegacao.vue'
    import Topo from './New/Topo.vue'
    import Formularios from './New/Formularios.vue'
    import FormularioVee from './New/FormularioVee.vue'

    import BigWrapper from './Final/BigWrapper.vue'
    
    import MainWrapper from './New/MainWrapper.vue'
    import MainWrapperFinal from './New/MainWrapperFinal.vue'
    import MainWrapperNew from './New/MainWrapperNew.vue'
    import Ultimo from './Final/Ultimo.vue'

    

    export default {
        setup() {
            const {y: scrollY} = useWindowScroll();

            return {
                Clock, Eye, Olho, scrollY
            }
        },
        components: {
            AppWrapper,
            
            Olho,
            Eye,
            Clock,
            // DropzoneInput,
            FormSteps,
            SideInfos,
            Navegacao,
            Topo,
            Formularios,
            MainWrapper,
            MainWrapperFinal,
            MainWrapperNew,
            Ultimo,
            Formularios,
            
            BigWrapper
        }
    }
</script>

<style scoped>
</style>