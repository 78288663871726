<template>
<div id="Eventos">
    
    <div class="">
        <div v-for="(eventPai, index) in eventoStore.events" class="w-full flex flex-col justify-between p-6 border-white/50" :class="{'border-t': index!=0}">
            <div class="max-w-32 max-h-16 mix-blend-multiply">
                <img class="w-full" :src="'/storage/img/logos/' + eventPai.slug + '.' + (eventPai.slug == 'mochakk' ? 'png' : 'svg')" alt="">
            </div>
            <div v-if="eventPai.slug == 'brunch-electronik'" class="pt-6 text-sm"><strong>NOTA:</strong><br>Para cada sessão de Brunch Electronik é cobrada uma taxa de inscrição.</div>
            <div v-for="event in eventPai.filhos" class=" w-full flex flex-col justify-between pt-6">
                <!-- <div class="max-w-16 max-h-8 mb-3">
                    <img class="w-full" :src="'/storage/img/logos/' + eventoPai.slug + '.svg'" alt="">
                </div> -->
                <div class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-6">
                    <div class="col-span-full">
                        <div class="font-bold">{{ event.name }}</div>
                    </div>
                    <button v-for="sessao in event.sessoes" @click="toggleSelecao(sessao.id)"
                            class="w-full aspect-square flex flex-col justify-between border border-white/50 hover:bg-white/30 p-3 rounded-xl"
                            :class="{'!bg-white/100 !border-2 !border-verde-500 shadow-xl': formStore.formdata.eventos.includes(sessao.id)}">
                            <div class="self-end relative size-8 border border-white/50 rounded-full flex items-center justify-center"
                                :class="{'!text-verde-500 bg-white': formStore.formdata.eventos.includes(sessao.id)}">
                                <transition name="check" mode="out-in">
                                    <svg v-if="formStore.formdata.eventos.includes(sessao.id)"
                                        class="check-icon size-8">
                                        <use xlink:href="/storage/img/new-icons.svg?file#carbon-checkmark-filled" /></svg>
                                </transition>
                            </div>
                            <div class="w-full text-left font-bold uppercase">{{ sessao.start_day }}<span v-if="sessao.start_day != sessao.end_day">-{{ sessao.end_day }}</span> {{ meses[sessao.start_month] }}</div>
                            
                        </button>
                </div>
            </div>
        </div>
    </div>
    <!-- <button @click="valida" class="w-full mt-6 p-6 bg-white flex justify-end items-center space-x-4" :class="{'opacity-50 cursor-default': !preenchidos}">
        <span class="text-xl font-black uppercase">Avançar</span>
        <svg class="size-12 text-black"><use xlink:href="/storage/img/new-icons.svg?file#carbon-arrow-right" /></svg>
    </button>
    <div v-if="!preenchidos" class="text-sm font-bold p-6">Tem de escolher pelo menos uma sessão para poder avançar.</div> -->
    
</div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useFormStore } from '@/stores/form.js'
const formStore = useFormStore();
import { useEventoStore } from '@/stores/eventos.js'
const eventoStore = useEventoStore();
import 'js-circle-progress'

import InputCheckbox from './InputCheckbox.vue'

const toggleSelecao = (eventoId) => {
    const index = formStore.formdata.eventos.indexOf(eventoId);
    if (index !== -1) {
        formStore.formdata.eventos.splice(index, 1);
    } else {
        formStore.formdata.eventos.push(eventoId);
    }
}

console.log('eventosComponent', formStore.step.component)

const meses = ref([
   '---',
   'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
])

const valida = () => {

    if(formStore.formdata.eventos.length > 0){
        formStore.stepNext()
    }




    // formStore.step.fields.forEach(field => {
    //     if(!field.valor) field.invalid = true
    // });

    // console.log(formStore.steps)

    // axios.post('/submit-form', formStore.step.fields).then(() => {
    //     form.processing = false;

    //     console.log()
    //     // closeModal();
    //     // nextTick().then(() => emit('confirmed'));

    // }).catch(error => {
    //     console.log(error.response.data)
        
    //     // form.processing = false;
    //     // form.error = error.response.data.errors.password[0];
    //     // passwordInput.value.focus();
    // });
}

const preenchidos = computed(() => {
    // var campos = formStore.step.fields.filter((field) => field.valor)
    // return campos.length
    return formStore.formdata.eventos.length > 0
});
</script>

<style scoped>
  .text-input {
    @apply block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-verde-600
  }
.text-label {
    @apply absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-verde-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6
}

.area-icon__wrapper {
    @apply flex size-16 shrink-0 items-center justify-center rounded-full bg-verde-500/10;
    .area-icon {
    @apply size-8 text-verde-500;
    }
}

  .invalid {
    input,
    .text-input {
        @apply text-red-500 border-red-500 focus:border-red-500
    }
    label,
    .text-label {
        @apply text-red-500 peer-focus:text-red-500
    }

    .area-icon__wrapper {
    @apply bg-red-500/10;
    .area-icon {
    @apply text-red-500;
    }
    }
  }

circle-progress::part(base) {
    @apply w-8 h-auto
}
circle-progress::part(value) {
	stroke-width: 6px;
    @apply stroke-black
}
circle-progress::part(circle) {
	stroke-width: 6px;
    @apply stroke-white
}
</style>