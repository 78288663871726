<script setup>
const year = new Date().getFullYear()
</script>

<template>
    <div class="conteudo conteudo-y font-medium flex flex-col space-y-1">
        <div class=" text-xs text-black">©{{ year }}</div>
            <div class="flex items-center space-x-2">
                <div class="text-black text-xs font-bold leading-[.9em] tracking-tight uppercase">MOY</div>
                <div class="w-6 h-0.5 bg-black"></div>
                <div class="text-black text-xs font-bold leading-[.9em] tracking-tight uppercase">Group</div>
            </div>
        <div class=" text-xs text-black">Todos os direitos reservados.</div>
    </div>
</template>