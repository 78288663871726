<script setup>
import {ref, watch, onMounted} from 'vue'
import ShuffleText from 'shuffle-text';
import Lenis from 'lenis'

const props = defineProps({
    icon: String,
    title: String,
    subtitle: String,
    text: String
})

const titulo = props.title
const subtitulo = props.subtitle
const mudaTitulo = ref(null)
const mudaSubtitulo = ref(null)
const pronto = ref(false)

// const lenis = new Lenis()

watch(() => props.title, (newTitle) => {
    // if(window) {
    //     window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth'
    //   });
    // }
    // if(lenis) {
    //     lenis.scrollTo('top', {
    //         duration: 0.15
    //     })
    // }
    scrollToTop()
    mudaTitulo.value.innerText = newTitle;
    var text = new ShuffleText(mudaTitulo.value);
    text.start();
});
watch(() => props.subtitle, (newSubtitle) => {
    mudaSubtitulo.value.innerText = newSubtitle;
    var subtext = new ShuffleText(mudaSubtitulo.value);
    subtext.start();
});

onMounted(() => {
    
    pronto.value = true
})

const scrollToTop = () => {
    const duration = 150; // Duration in milliseconds
    const start = window.scrollY;
    const startTime = performance.now();

    const easeInOutQuad = (t) => t < 0.5 ? 2*t*t : -1+(4-2*t)*t;

    const scroll = () => {
        const currentTime = performance.now();
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);
        const easedProgress = easeInOutQuad(progress);

        window.scrollTo(0, start * (1 - easedProgress));

        if (timeElapsed < duration) {
            requestAnimationFrame(scroll);
        }
    };

    requestAnimationFrame(scroll);
}
</script>

<template>
    <!-- <div class="conteudo conteudo-y !conteudo-mini-b">
        <div class="flex items-center space-x-3 mb-4">
            <svg class="relative size-10 text-green-500"><use :xlink:href="icon" /></svg>
            <div ref="mudaTitulo" class="text-2xl font-bold text-green-500">{{ titulo }}</div>
        </div>
        <div class="text-sm w-full md:w-3/4 lg:w-2/3 xl:w-1/2 font-medium text-black/50" v-html="text"></div>
    </div> -->



    <div class="conteudo conteudo-y !conteudo-mini-b">
        <div class="relative size-10 mb-3">
            <Transition name="top-icon">
                <svg :key="icon" class="absolute top-1/2 -translate-x-1/2 left-1/2 -translate-y-1/2 size-10 text-green-500"><use :xlink:href="icon" /></svg>
            </Transition>
        </div>
        <div class="transition-all duration-500">
            <div ref="mudaTitulo" class="font-bold leading-[.95em] tracking-tight text-xl">{{ titulo }}</div>
            <div ref="mudaSubtitulo" class="text-2xl text-black-40 transition-all duration-500 ">{{ subtitulo }}</div>
        </div>
    </div>
</template>
<style scoped>
.top-icon-enter-active,
.top-icon-leave-active {
  transition: all 0.5s ease;
}

.top-icon-enter-from {
  @apply size-0 rotate-180
}

.top-icon-leave-to {
  @apply size-0 -rotate-180
}
</style>