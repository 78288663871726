<script setup>
import {computed, ref, watch} from 'vue'
import { useFormStore } from '@/stores/form.js'
import { useEventoStore } from '@/stores/eventos.js'
import ShuffleText from 'shuffle-text';

import useErrors from '@/use/error';

import Navegacao from './Navegacao.vue'
import FormsWrapper from './Blocos/FormsWrapper.vue'
import SidebarCima from './Blocos/SidebarCima.vue'
import SidebarBaixo from './Blocos/SidebarBaixo.vue'
import TextoCima from './Blocos/TextoCima.vue'
import TextoBaixo from './Blocos/TextoBaixo.vue'

const formStore = useFormStore()
const eventoStore = useEventoStore()

const errors = useErrors()

const year = new Date().getFullYear()

const props = defineProps({
    calendar: Object,
    eventos: Object
})

eventoStore.setCalendar(props.calendar)
eventoStore.setEvents(props.eventos)
// formStore.fakeContent(2)

const step = computed(() => {
    return formStore.step
})

const prev = () => {
  console.log('next', formStore.stepCurrent)
  formStore.stepPrev();
}

const next = () => {
  console.log('next', formStore.stepCurrent)
  formStore.stepNext();
}
const titulo = formStore.step.title


const mudaTitulo = ref(null)

watch(() => formStore.stepCurrent, (newTitle) => {
    mudaTitulo.value.innerText = formStore.step.title;
    var text = new ShuffleText(mudaTitulo.value);
    text.start();
});
</script>

<template>
    <div id="MainWrapperNew">
<div class="fixed inset-0 overflow-hidden">
        <transition name="info-foodtruck--truck">
        <div v-if="formStore.step.slug == 'foodtruck'" class="absolute top-[36dvh] left-[20dvw] mix-blend-multiply">
            <img class="size-48" src="/storage/img/trucks/1.svg" alt="">
        </div>
    </transition></div>

        <div id="MainWrapperNew--infos" class="lg:min-h-[100dvh] w-full flex flex-col justify-between">
            <div class="conteudo conteudo-y pb-0">
                <div>
                    <img class="relative size-10 mb-4 transition-all duration-500 "
                        :class="{'opacity-50 scale-75 mb-3': formStore.mostraSteps}" src="/storage/img/moyg-eye.svg" alt="">
                    <div class="transition-all duration-500 origin-top-left"
                        :class="{'scale-50': formStore.mostraSteps}">
                        <div class="font-bold leading-[.95em] tracking-tight text-4xl">Food Trucks</div>
                        <div class="text-5xl text-black-40 transition-all duration-500 "
                            :class="{'opacity-50': formStore.mostraSteps}">Formulário de Inscrição</div>
                    </div>
                </div>
            </div>
            <div class="relative w-[100dvw] h-10 overflow-hidden">
                <div class="absolute top-0 w-[100dvw] -translate-x-0 h-0.5 bg-white/50 transition-all duration-500"
                    :class="{'!-translate-x-[200dvw]': formStore.mostraSteps}"></div>
            </div>
            <div class="relative" :class="{'z-50': !formStore.mostraSteps}">
                <div class="relative">
                    <div class="absolute bottom-0 left-0 flex conteudo">
                        <!-- <transition name="intro-texto">
                            <div v-if="formStore.mostraSteps">
                                <div class="text-7xl font-extralight bg-green-500">{{formStore.stepCurrent }}<span v-if="formStore.steps" class="text-4xl align-bottom">/ {{formStore.steps.length - 1 }}</span></div>
                            </div>
                        </transition> -->
                        <transition name="intro-texto">
                            <div v-if="!formStore.mostraSteps">
                                <div class="text-4xl">Agradecemos o seu interesse em participar nos eventos Made Of You. Por favor, leia atentamente as informações abaixo e preencha o formulário de candidatura para que possamos considerá-lo.</div>
                                <div class="flex">
                                    <button @click="formStore.setStep(0)" class="relative inline-flex items-center justify-center my-6 p-4 px-6 py-3 overflow-hidden text-white font-medium bg-verde-500 transition duration-300 ease-out border-2 border-verde-500 rounded-full shadow-md group">
                                        <span class="absolute inset-0 flex items-center justify-center w-full h-full bg-white duration-300 -translate-y-full text-verde-500 group-hover:translate-y-0 ease">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                        </span>
                                        <span class="font-bold absolute flex items-center justify-center w-full h-full text-white transition-all duration-300 transform group-hover:translate-y-full ease">Começar</span>
                                        <span class="relative invisible">Começar</span>
                                    </button>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <transition name="intro-steps">
                    <div v-if="formStore.mostraSteps">
                        <div class="conteudo relative flex flex-col justify-between space-y-4 overflow-hiden">
                            <!-- <div class="w-3/4 text-base font-medium" v-html="step.text"></div> -->
                            <div class="text-7xl font-extralight">{{formStore.stepCurrent }}<span v-if="formStore.steps"
                                    class="text-4xl align-bottom">/ {{formStore.steps.length - 1 }}</span></div>
                            <!-- <div v-if="step" class="text-7xl font-black">{{ step.title }}</div> -->
                            <div ref="mudaTitulo" class="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-black">{{ titulo }}</div>
                        </div>
                    </div>
                </transition>
                <div class="conteudo conteudo-y pt-6">
                    <div class="text-xs opacity-50"><strong>©{{ year }} Made of You Group</strong><br>All rights
                        reserved</div>
                </div>
            </div>
            <div class="block relative lg:absolute lg:top-0 lg:right-0 w-[100dvw] min-h-[100dvh] lg:max-h-[100dvh] lg:overflow-y-auto">
                <transition name="forms-wrapper">
                    <div v-if="formStore.mostraSteps">
                        <FormsWrapper></FormsWrapper>
                    </div>
                </transition>
            </div>
        </div>
        
        <!-- <transition :name="formStore.vaiEntrar != formStore.stepCurrent && formStore.vaiEntrar > formStore.stepCurrent ? 'slide--left' : 'slide--right'">
                    <div v-if="formStore.processingForm" class="fixed inset-0 bg-violet-500">{{ formStore.vaiEntrar }} -- {{ formStore.stepCurrent }}</div>
            </transition> -->

        <!-- <navegacao></navegacao> -->
            <div v-if="1>2">
                
            <div class="fixed w-[100dvw] overflow-hidden">

                <div class="w-full min-h-[100dvh] h-full grid lg:grid-cols-2">
                    <!-- <div class="absolute w-full h-10 bg-red-500"></div> -->
                    <div id="MainWrapperNew--infos" class="relative w-full h-full flex flex-col justify-between">
                        <div class="relative">
                            <div class="conteudo conteudo-y">
                                <div>
                                    <img class="relative size-10 mb-4 transition-all duration-500 "
                                        :class="{'opacity-50 scale-75 mb-3': formStore.mostraSteps}"
                                        src="/storage/img/moyg-eye.svg" alt="">
                                    <div class="transition-all duration-500 origin-top-left"
                                        :class="{'scale-50': formStore.mostraSteps}">
                                        <div class="font-bold leading-[.95em] tracking-tight text-4xl">Food Trucks</div>
                                        <div class="text-5xl text-black-40 transition-all duration-500" :class="{'opacity-50': formStore.mostraSteps}">Formulário de Inscrição</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="relative">
                            <div class="absolute top-0 w-[100dvw] -translate-x-0 h-0.5 bg-white/50 transition-all duration-500"
                                :class="{'!-translate-x-[200dvw]': formStore.mostraSteps}"></div>
                        </div>
                        <div class="conteudo conteudo-y">
                            <div
                                class="relative w-[100dvw] h-100dvhl flex flex-col justify-between space-y-4 overflow-hiden">
                                <!-- <div class="w-3/4 text-base font-medium" v-html="step.text"></div> -->
                                <div class="text-7xl font-extralight">{{formStore.stepCurrent }}<span v-if="formStore.steps"
                                        class="text-4xl align-bottom">/ {{formStore.steps.length - 1 }}</span></div>
                                <div ref="mudaTitulo" class="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl font-black">{{ titulo }}</div>
                                <div class="text-xs opacity-50"><strong>©{{ year }} Made of You Group</strong><br>All rights
                                    reserved</div>
                            </div>
                        </div>
                    </div>
                </div>




                <div class="fixed inset-0">
                    <transition name="forms-wrapper">
                        <div v-if="formStore.mostraSteps">
                            <!-- <FormsWrapper2></FormsWrapper2> -->
                        </div>
                    </transition>

                    <!-- <FormsWrapper></FormsWrapper> -->
                </div>

                <navegacao></navegacao>
            </div>
            <div class="fixed top-6 right-6">
                <div class="rotate-90 origin-top-right">
                    <div class="-translate-y-1/2 translate-x-full flex items-center space-x-2">
                        <div class="text-xs font-bold">MOY</div>
                        <div class="w-16 h-0.5 bg-black/30"></div>
                        <div class="text-xs font-bold">GROUP</div>
                    </div>
                </div>
            </div>
            </div>
        </div>
</template>
<style scoped>
.linha-guia {
    @apply border border-red-500/0
}
.tapa {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%)
}
</style>