<script setup lang="ts">
import StepTitle from './MultiStep/Blocos/StepTitle.vue'
import Informacoes from './MultiStep/Blocos/Informacoes.vue'
import FormsWrapper from './MultiStep/Blocos/FormsWrapper.vue'

import { useMultiStepStore } from '@/stores/multiStep.js'
import { useEventoStore } from '@/stores/eventos.js'
const multiStepStore = useMultiStepStore()
const eventoStore = useEventoStore()

const componentMap = {
    Informacoes: Informacoes,
    FormsWrapper: FormsWrapper
};

eventoStore.fetchEvents();
</script>

<template>
  <div>
    <StepTitle :icon="'/storage/img/new-icons.svg?file#' + (multiStepStore.mostraSteps ? multiStepStore.step.icon : 'carbon-restaurant')" :title="multiStepStore.mostraSteps ? multiStepStore.step.title : 'Restauração'" :subtitle="multiStepStore.mostraSteps ? (multiStepStore.currentStepIdx + '/' + multiStepStore.steps.length) : 'Formulário de Inscrição'"></StepTitle>
    
    <div class="w-full h-full">
      <Transition name="fade" mode="out-in">
          <div :key="multiStepStore.mostraSteps ? multiStepStore.currentStepIdx : multiStepStore.mostraSteps">
              <div class="conteudo conteudo-y pt-0">
                  <div class="relative">
                      <div class="text-sm w-full md:w-3/4 lg:w-2/3 xl:w-1/2 font-medium text-black/50 opacity-0">
                          Agradecemos o seu interesse em participar nos eventos Made Of You. Por favor, leia atentamente as informações abaixo e preencha o formulário de candidatura para que possamos considerá-lo.
                      </div>
                      <div v-if="!multiStepStore.mostraSteps" class="absolute top-0 left-0 text-sm w-full md:w-3/4 lg:w-2/3 xl:w-1/2 font-medium text-black/50">
                          Agradecemos o seu interesse em participar nos eventos Made Of You. Por favor, leia atentamente as informações abaixo e preencha o formulário de candidatura para que possamos considerá-lo.
                      </div>
                      <div v-else class="absolute top-0 left-0 text-sm w-full md:w-3/4 lg:w-2/3 xl:w-1/2 font-medium text-black/50" v-html="(multiStepStore.step.text ?? '')"></div>
                  </div>
              </div>
          </div>
      </Transition>

      <div class="border-y border-neutral-200 overflow-hidden">
        <Transition name="top-btn" mode="out-in">
            <div :key="multiStepStore.mostraSteps ? 'btn-volta' : 'btn-avanca'">
              <button v-if="multiStepStore.step.slug != 'confirmacao'" @click="!multiStepStore.mostraSteps ? multiStepStore.setInicio() : multiStepStore.goToPrev()" class="conteudo py-6 hover:bg-neutral-50 flex items-center space-x-4 text-black" :class="{'opacity-50 cursor-default': multiStepStore.processingForm, 'justify-end': !multiStepStore.mostraSteps}">
                <span v-if="!multiStepStore.mostraSteps" class="text-lg font-black" v-html="'Iniciar Formulário'"></span>
                <svg v-if="multiStepStore.processingForm" class="size-6"><use class="animate-spin origin-center" xlink:href="/storage/img/new-icons.svg?file#carbon-rotate-180" /></svg>
                <svg class="size-6" v-else><use :xlink:href="'/storage/img/new-icons.svg?file#carbon-arrow-' + (!multiStepStore.mostraSteps ? 'right' : 'left')" /></svg>
                <span v-if="multiStepStore.mostraSteps" class="text-lg font-black" v-html="'Voltar'"></span>
            </button>
          </div>
        </Transition>
    </div>

    <Transition name="fade" mode="out-in">
        <component :key="multiStepStore.mostraSteps ? 'forms-wrapper' : 'informacoes'" :is="multiStepStore.mostraSteps ? componentMap.FormsWrapper : componentMap.Informacoes"></component>
    </Transition>
</div>


    
  </div>
</template>

<style>
input,
select {
  margin: 10px 0;
  display: block;
}
</style>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from {
  @apply opacity-0 translate-x-full
}

.fade-leave-to {
  @apply delay-150 opacity-0 -translate-x-full
}

.top-btn-enter-active,
.top-btn-leave-active {
  transition: all 0.5s ease;
}

.top-btn-enter-from {
  @apply translate-y-full
}

.top-btn-leave-to {
  @apply -translate-y-full
}
</style>
