<script setup>
import { watch, ref, onMounted, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useFormNewStore } from '@/stores/formNew.js'

const formNewStore = useFormNewStore()


import Message from '@/Last/Blocos/Message.vue'
import FaleConnosco from '@/Last/Blocos/FaleConnosco.vue'


import Eventos from '@/Last/Steps/Eventos.vue'
import InfoEmpresa from '@/Last/Steps/InfoEmpresa.vue'
import InfoFoodtruck from '@/Last/Steps/InfoFoodtruck.vue'
import Documentos from '@/Last/Steps/Documentos.vue'
import Confirmacao from '@/Last/Steps/Confirmacao.vue'

const componentMap = {
    Eventos: Eventos,
    InfoEmpresa: InfoEmpresa,
    InfoFoodtruck: InfoFoodtruck,
    Documentos: Documentos,
    Confirmacao: Confirmacao
};

const { step, stepCurrent, processingForm } = storeToRefs(formNewStore)
</script>

<template>
    <div class="form-wrapper">
        

        <div class="relative">
            <transition name="step" mode="out-in">
            <div class="w-full h-full" :key="formNewStore.step.component">
                <!-- <PerfectScrollbar> -->
                    
                        <component :is="componentMap[formNewStore.step.component]"></component>

                    <Message v-if="step.mensagemErro" :text="step.mensagemErro"></Message>
                    
                    <button v-if="step.slug != 'confirmacao'" @click="step.valid && !processingForm ? formNewStore.stepNext() : ''" class="conteudo py-6 border-y bg-white border-neutral-200 hover:bg-neutral-100 flex items-center justify-end space-x-4 text-black" :class="{'text-opacity-20 cursor-default': !step.valid || processingForm, 'hover:!bg-red-50': !step.valid}">
                        <span class="text-lg font-black">Avançar</span>
                        <svg v-if="processingForm" class="size-10"><use class="animate-spin origin-center" xlink:href="/storage/img/new-icons.svg?file#carbon-rotate-180" /></svg>
                        <svg class="size-10" v-else><use xlink:href="/storage/img/new-icons.svg?file#carbon-arrow-right" /></svg>
                    </button>
            </div>
        </transition>
                    </div>

        <FaleConnosco></FaleConnosco>
        
        </div>
</template>

<!-- <style>
.form-wrapper .ps {
    @apply w-full h-full overflow-y-auto
  }
.form-wrapper {
    .input-wrapper {
        @apply mb-6;
        .input-text__label {
            @apply block mb-2 text-sm font-bold text-neutral-700;
        }
        .input-text__input {
            @apply bg-neutral-50 border border-neutral-500 text-neutral-900 placeholder-neutral-700 text-sm rounded-lg focus:ring-neutral-500 focus:border-neutral-500 block w-full p-2.5;
        }
        .input-text__mensagem {
            @apply mt-2 text-sm text-neutral-600;
        }

        &.input--success {
            .input-text__label {
                @apply text-green-700;
            }
            .input-text__input {
                @apply bg-green-50 border-green-500 text-green-900 placeholder-green-700 focus:ring-green-500 focus:border-green-500;
            }
            .input-text__mensagem {
                @apply text-green-600;
            }
        }
        &.input--error {
            .input-text__label {
                @apply text-red-700;
            }
            .input-text__input {
                @apply bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500;
            }
            .input-text__mensagem {
                @apply text-red-600;
            }
        }
    }

    
}
</style> -->
