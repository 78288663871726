<template>
  <div :class="stepClass">
    <div class="steps-bg fixed top-0 left-0 w-[100dvw] h-[100dvh] flex transition-all duration-1000"></div>
      <div class="wrapper fixed top-0 left-0 w-[600dvw] h-[100dvh] flex transition-all duration-500">
          <!-- <Step0></Step0> -->
          <Intro></Intro>
          <Step v-for="step in formStore.steps" :step="step"></Step>
        
          <!-- <Step0></Step0> -->
          <!-- <Step1></Step1>
          <Step2></Step2>
          <Step3></Step3>
          <Step4></Step4> -->
      </div>

      
      <div class="fixed top-12 right-12 flex">
          <div @click="prev" class="size-20 rounded-full bg-black flex items-center justify-center">
              </div> <div @click="next" class="size-20 rounded-full bg-black flex items-center justify-center">
          </div>
      </div>


    <div class="fixed top-0 left-0 w-[100dvw] h-24 backdrop-blur flex transition-all duration-1000"></div>
    <!--  -->
      <!-- <div class="fixed top-24 left-0 bottom-12 right-0 bg-center bg-cover opacity-5" style="background-image:url(/storage/img/dots.svg)"></div> -->
      <transition name="bullets">
          <div v-if="formStore.stepCurrent > 0" class="absolute top-16 left-0 conteudo flex flex-col">
                <div class="bullets-text text-sm mb-1 text-black/50 flex justify-between items-end">
                  <div><strong>0{{ formStore.stepCurrent }}</strong>/0{{ formStore.steps.length }}</div>

                  <div class="font-bold">{{ formStore.percentagem }}%</div>
                </div>
                <div class="bullets-rail relative w-full h-1 rounded-full bg-neutral-400 overflow-hidden">
                    <div class="absolute top-0 left-0 h-full bg-black/80 rounded-full transition-all duration-500" :style="'width:' + formStore.percentagem + '%'"></div>
                </div>
                <div class="w-full -mt-2 h-5 grid grid-cols-4 border-l border-neutral-400">
                  <div class="border-r border-neutral-400" v-for="i in 4"></div>
                </div>
            </div>
          </transition>
      <!-- <div class="absolute top-0 left-0 w-[400dvw] h-[100dvh] flex">
    <div class="w-screen h-[100dvh] py-36 overflow-y-auto">
    <div class="grid grid-cols-12 gap-8">
        <div class="col-span-9 text-7xl">Formulário de Inscrição <strong>Food Trucks</strong></div>
        <div class="col-span-9 text-xl">Agradecemos o seu interesse em participar nos eventos Made Of You. Por favor, leia atentamente as informações abaixo e preencha o formulário de candidatura para que possamos considerá-lo.<br><br>Estamos entusiasmados para analisar a sua inscrição. Se for selecionado, entraremos em contato diretamente por e-mail.<br><br>Na próxima página, encontrará todas as informações necessárias para concluir a sua inscrição.</div>
        <div class="col-span-9 rounded-3xl p-8 bg-verde-500/20">
            <div class="grid grid-cols-3 gap-8">
                <div>
                    <div class="font-bold uppercase text-xs pb-1 text-black border-b border-black">Processo de<br>Inscrição</div>
                    <div class="text-sm mt-6">Esteja ciente de que o preenchimento deste formulário não garante automaticamente a sua participação. Entraremos em contato diretamente caso a sua candidatura seja selecionada para avançar para a próxima fase.</div>
                </div>
                <div>
                    <div class="font-bold uppercase text-xs pb-1 text-black border-b border-black">Taxas de<br>Participação</div>
                    <div class="text-sm mt-6">Para validar a inscrição, é necessário o pagamento de uma taxa de participação. Esta taxa é fixa e não negociável. Os candidatos selecionados serão informados sobre os procedimentos de pagamento. Cada evento tem uma taxa de inscrição diferente.</div>
                </div>
                <div>
                    <div class="font-bold uppercase text-xs pb-1 text-black border-b border-black">Política de<br>Pagamentos</div>
                    <div class="text-sm mt-6">Todas as transações devem ser realizadas exclusivamente através do sistema do evento. Qualquer tentativa de utilizar outros métodos de pagamento resultará na rescisão imediata da inscrição e inclusão na lista negra do evento.</div>
                </div>
            </div>
        </div>
    </div>
</div>
  </div> -->
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
const formStore = useFormStore()
const useFormStore = useFormStore()
import Intro from './Steps/Intro.vue'
import Step from './Steps/Step.vue'



const props = defineProps({
  fields: Object,
  name: String,
  multiple: String
})

const prev = () => {
  console.log('prev', formStore.stepCurrent)
  formStore.stepPrev();

  // wrapper.value.style.translateX = '-' + (step.value * 100) + 'dvw';
}

const next = () => {
  console.log('next', formStore.stepCurrent)
  formStore.stepNext();

  // wrapper.value.style.translateX = '-' + (step.value * 100) + 'dvw';
}

const stepClass = computed(() => {
  return 'step' + formStore.stepCurrent
})
</script>

<style scoped>
    .step0 {
      .steps-bg {
        @apply bg-transparent;
      }
      .wrapper {
        @apply -translate-x-0;
      }
    }
    .step1 {
      .steps-bg {
        @apply bg-neutral-300;
      }
      .wrapper {
        @apply -translate-x-[100dvw];
      }
    }
    .step2 {
      .steps-bg {
        @apply bg-verde-200;
      }
      .wrapper {
        @apply -translate-x-[200dvw];
      }
    }
    .step3 {
      .steps-bg {
        @apply bg-verde-700;
      }
      .wrapper {
        @apply -translate-x-[300dvw];
      }
    }  
    .step4 {
      .steps-bg {
        @apply bg-transparent;
      }
      .wrapper {
        @apply -translate-x-[400dvw];
      }
    }  

  /* .ps {
    @apply w-1/6 h-[100dvh] overflow-y-auto
  } */

  
</style>
