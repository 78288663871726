<template>
    <div class="flex flex-col gap-2">
    <label for="username" class="text-xs font-bold" :class="{'text-red-500': errorMessage}">{{ title }}</label>
    <InputText id="username" v-model="value" aria-describedby="username-help" :invalid="errorMessage ? true : false" />
    <small v-if="errorMessage" class="text-red-500">{{ errorMessage }}</small>
</div>
  </template>
  
  <script setup>

import { ref } from 'vue';
  import { useField } from 'vee-validate';
  import InputText from 'primevue/inputtext';
  
  const props = defineProps({
    name: String,
    type: String,
    title: String,
  });
  
  // The `name` is returned in a function because we want to make sure it stays reactive
  // If the name changes you want `useField` to be able to pick it up
  const { value, errorMessage } = useField(() => props.name);

const myInputStyle = ref({
    root: 'bg-gray-100 dark:bg-gray-950 p-4 text-gray-900 dark:text-gray-50 rounded-full placeholder:text-gray-500 border border-transparent'
});

  </script>
  