<template>
    <div class="conteudo conteudo-mini-y">

        <div class="min-h-full flex flex-col justify-between">
            <div class="grid grid-cols-6 gap-6">
                <div v-for="field in formNewStore.foodtruck"
                    :class="{'col-span-full': field.type == 'text' || field.type == 'file', 'col-span-3': field.type == 'number', 'col-span-2': field.type == 'radio' }">
                    <div v-if="field.type == 'file'" class="input-wrapper">
                        <label :for="field.slug" class="input-text__label">{{field.title }} *</label>
                        <div class="p-2.5 rounded-2xl border border-neutral-200">
                            <DropzoneInput :key="'field-'+field.id":field="field"></DropzoneInput>


                        </div>
                    </div>

                    <!-- <div v-else-if="field.type != 'radio'" class="input-wrapper" :class="{'input--success': 1>2, 'input--error': (formNewStore.erros && formNewStore.erros[field.slug]) || !field.valor }">
                    <label :for="field.slug" class="input-text__label">{{field.title }} *</label>
                    <input :type="field.type" :name="field.slug" class="input-text__input" :placeholder="field.placeholder ?? ''" :required="true" v-model="field.valor">
                    <p class="input-text__mensagem" v-if="formNewStore.erros && formNewStore.erros[field.slug]" v-html="formNewStore.erros[field.slug][0]"></p>
                </div> -->
                    <div v-else-if="field.type != 'radio'" class="input-wrapper"
                        :class="{'input--success': 1>2, 'input--error': (formNewStore.erros && formNewStore.erros[field.slug]) || !field.valor }">
                        <TextInput :field="field"></TextInput>
                    </div>

                    <!-- <button v-else @click="field.valor = !field.valor" class="w-full flex items-center space-x-4 p-6 bg-white">
                    <div class="relative size-8 bg-neutral-200 rounded-full flex items-center justify-center"
                        :class="{'border-black/100': field.valor}">
                        <transition name="check" mode="out-in">
                            <svg v-if="field.valor"
                                class="check-icon size-6">
                                <use xlink:href="/storage/img/new-icons.svg?file#carbon-checkmark-filled" /></svg>
                        </transition>
                    </div>
                    <div class="w-full text-left text-xs">{{field.title }}</div>
                </button> -->
                    <div v-else>
                        <Checkbox :field="field"></Checkbox>
                    </div>

                    <!-- <button v-else @click="field.valor = !field.valor"
                    class="w-full aspect-square flex flex-col justify-between border border-neutral-200 hover:bg-white/30 p-3 rounded-xl"
                    :class="{'!bg-white/100 !border-2 !border-verde-500 shadow-xl': field.valor}">
                    <div class="self-end relative size-8 border border-neutral-200 rounded-full flex items-center justify-center"
                        :class="{'!text-verde-500 bg-white': field.valor}">
                        <transition name="check" mode="out-in">
                            <svg v-if="field.valor"
                                class="check-icon size-8">
                                <use xlink:href="/storage/img/new-icons.svg?file#carbon-checkmark-filled" /></svg>
                        </transition>
                    </div>
                    <div class="w-full text-left font-bold text-sm">{{field.title }}</div>
                    
                </button> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useFormNewStore } from '@/stores/formNew.js'
const formNewStore = useFormNewStore()

import TextInput from '../Form/TextInput.vue'
import Checkbox from '../Form/Checkbox.vue'

import DropzoneInput from '../DropzoneInput.vue'
import Uploader from '../Uploader.vue'

import FicheiroCarregado from '../FicheiroCarregado.vue';


const pronto = ref(false)
onMounted(() => {
    pronto.value = true
});

const toggleSimNao = (slug) => {
    var field = formNewStore.step.fields.filter((field) => field.slug == slug)
    
    if(field.valor == 'Não') {
        field.valor = 'Sim'
    } else {
        field.valor = 'Não'
    }
}

formNewStore.step.mensagemErro = computed(() => {
    var obrigatorios = formNewStore.step.fields.filter((field) => field.errorMessage == 'Este campo é obrigatório.' || (field.required && !field.valor))
    if(obrigatorios.length > 0) return 'Todos os campos marcados com (*) são obrigatórios.'


    var outros = formNewStore.step.fields.filter((field) => field.errorMessage)
    if(outros.length > 0) return outros[0].errorMessage
    
    return false
});

formNewStore.step.valid = computed(() => {
    var campos = formNewStore.step.fields.filter((field) => !field.valor)
    return campos.length > 0 ? false : true
});
</script>