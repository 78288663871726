<template>
    <div class="p-6">
    
    <div class="min-h-full flex flex-col justify-between">
        <div class="grid grid-cols-6 gap-6">
            <div v-for="field in formStore.foodtruck" :class="{'col-span-full': field.type == 'text' || field.type == 'file', 'col-span-3': field.type == 'number', 'col-span-2': field.type == 'radio'}">
		        <div v-if="field.type == 'file'" class="input-wrapper">
                    <label :for="field.slug" class="input-text__label">{{field.title }} *</label>
                    <dropzone-input :name="field.slug" :multiple="field.slug == 'fotos' ? 1 : 0"></dropzone-input>
                </div>
                  
                <div v-else-if="field.type != 'radio'" class="input-wrapper" :class="{'input--success': 1>2, 'input--error': (formStore.erros && formStore.erros[field.slug]) || !field.valor }">
                    <label :for="field.slug" class="input-text__label">{{field.title }} *</label>
                    <input :type="field.type" :name="field.slug" class="input-text__input" :placeholder="field.placeholder ?? ''" :required="true" v-model="field.valor">
                    <p class="input-text__mensagem" v-if="formStore.erros && formStore.erros[field.slug]" v-html="formStore.erros[field.slug][0]"></p>
                </div>

                <!-- <button v-else @click="field.valor = !field.valor" class="w-full flex items-center space-x-4 p-6 bg-white">
                    <div class="relative size-8 bg-neutral-200 rounded-full flex items-center justify-center"
                        :class="{'border-black/100': field.valor}">
                        <transition name="check" mode="out-in">
                            <svg v-if="field.valor"
                                class="check-icon size-6">
                                <use xlink:href="/storage/img/new-icons.svg?file#carbon-checkmark-filled" /></svg>
                        </transition>
                    </div>
                    <div class="w-full text-left text-xs">{{field.title }}</div>
                </button> -->

                <button v-else @click="field.valor = !field.valor"
                    class="w-full aspect-square flex flex-col justify-between border border-white/50 hover:bg-white/30 p-3 rounded-xl"
                    :class="{'!bg-white/100 !border-2 !border-verde-500 shadow-xl': field.valor}">
                    <div class="self-end relative size-8 border border-white/50 rounded-full flex items-center justify-center"
                        :class="{'!text-verde-500 bg-white': field.valor}">
                        <transition name="check" mode="out-in">
                            <svg v-if="field.valor"
                                class="check-icon size-8">
                                <use xlink:href="/storage/img/new-icons.svg?file#carbon-checkmark-filled" /></svg>
                        </transition>
                    </div>
                    <div class="w-full text-left font-bold text-sm">{{field.title }}</div>
                    
                </button>
	        </div>
        </div>
    </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useFormStore } from '@/stores/form.js'
const formStore = useFormStore()

    import DropzoneInput from '../DropzoneInput.vue'



const pronto = ref(false)
onMounted(() => {
    pronto.value = true
});

const toggleSimNao = (slug) => {
    var field = formStore.step.fields.filter((field) => field.slug == slug)
    
    if(field.valor == 'Não') {
        field.valor = 'Sim'
    } else {
        field.valor = 'Não'
    }
}
</script>