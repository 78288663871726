<template>
    <div class="conatainer">
      <!-- Set the ref of the component so we can get access to it in the script -->
      <!-- We are using an inline style to set the rotaion of the eye in degrees -->
      <svg
        ref="eye"
        class="eye"
        :style="`transform: rotate(${rotationDegrees - 40}deg)`"
        viewBox="0 0 33 33"
        fill="none"
      >
        <path
          d="M0 16.3895C0 25.4415 7.33733 32.7788 16.388 32.7788C25.44 32.7788 32.7773 25.4415 32.7773 16.3895C32.7773 7.33748 25.44 0.000144958 16.388 0.000144958C7.33733 0.000144958 0 7.33748 0 16.3895Z"
          fill="#FFF7E8"
        />
        <path
          d="M18.901 12.1063C21.109 14.5503 24.4383 15.1396 26.3356 13.4249C28.2343 11.7103 27.9823 8.33826 25.773 5.89559C23.5636 3.45159 20.2356 2.86093 18.3383 4.57693C16.4396 6.29159 16.693 9.66226 18.901 12.1063Z"
          fill="#7860AA"
        />
        <path
          d="M23.6407 12.9819C24.1247 12.9819 24.8193 12.8753 25.3807 12.3686C26.67 11.2033 26.366 8.67527 24.7167 6.84993C23.6793 5.7046 22.3033 5.01927 21.034 5.01927C20.55 5.01927 19.854 5.12593 19.2927 5.63393C18.71 6.16193 18.4273 6.9886 18.502 7.96193C18.5833 9.06993 19.102 10.2033 19.958 11.1513C20.9953 12.2966 22.3713 12.9819 23.6407 12.9819Z"
          fill="#2A0D3B"
        />
        <path
          d="M25.0221 3.04376C24.3261 2.7331 23.3661 3.3651 22.8781 4.45576C22.3888 5.54776 22.5581 6.68376 23.2555 6.99576C23.9515 7.30776 24.9115 6.67576 25.3995 5.58376C25.8861 4.49176 25.7181 3.35443 25.0221 3.04376Z"
          fill="white"
        />
      </svg>
    </div>
  </template>
  
  <script lang="ts" >
  import { ref, defineComponent, onMounted } from "vue";
  import {
    debouncedWatch,
    throttledWatch,
    useMouse,
    useWindowSize,
  } from "@vueuse/core";
  
  export default defineComponent({
    setup() {
      // Here we are using the vueuse functions to get the mouse x & y and the window size
      const { x: mouseX, y: mouseY } = useMouse();
      const { width, height } = useWindowSize();
  
      // Here we are setting the variables we will need, including the eye svg reference
      const eye = ref(null);
      const rotationDegrees = ref(0);
      const eyeLocation = ref(undefined);
  
      // Get the eye loaction on mounted
      onMounted(() => {
        eyeLocation.value = eye.value.getBoundingClientRect();
      });
  
      // Use the debounce watch function to get the
      // eye location when the window height and width cahnge
      debouncedWatch(
        [width, height],
        () => {
          eyeLocation.value = eye.value.getBoundingClientRect();
        },
        { debounce: 200 }
      );
  
      // Using the throttle watch function, on mouse x & y change
      throttledWatch(
        [mouseX, mouseY],
        ([x, y]) => {
          if (eyeLocation.value) {
            // This calculates the radian offset between the eye and the mouse
            const radianDegrees = Math.atan2(
              x - eyeLocation.value.right,
              y - eyeLocation.value.top
            );
            // Now we need to convert it into a degrees value we can use in css
            rotationDegrees.value = radianDegrees * (180 / Math.PI) * -1 + 180;
          }
        },
        // Because an avenerage screen only refreshes at 60 hertz,
        // we can throttle this to 60 times per second
        { throttle: 1000 / 60 }
      );
  
      // We must return the eye component reference for it to work,
      // we also returning the rotaion degress
      return { eye, rotationDegrees };
    },
  });
  </script>
  
  <style>
  .conatainer {
    /* Set backgound colour and center eye */
    background-color: black;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .eye {
    /* Set eye size */
    height: 3rem;
  }
  body {
    /* Set body to fit screen */
    margin: 0;
    height: 100vh;
  }
  </style>