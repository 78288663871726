<template>
     <div class="fixed inset-0 bg-white overflow-hidden">
        <video class="absolute w-full h-full object-cover opacity-70" playsinline autoplay muted loop poster="/storage/img/bg.jpg">
            <source src="/storage/img/bg.mp4" type="video/mp4">
        </video>
    </div>
    <div class="fixed inset-0">
        <div class="w-full  h-full flex flex-col justify-between">
            <topo></topo>
        </div>
        <div class="col-span-3 w-[100dvw] h-[100dvh] grid grid-cols-10 bg-teal-500">Agradecemos o seu interesse em participar nos eventos Made Of You. Por favor, leia atentamente as informações abaixo e preencha o formulário de candidatura para que possamos considerá-lo.</div>    
    </div>
    <div v-for="(texto, titulo) in colunas">
        'PROCESSO DE INSCRIÇÃO': "Esteja ciente de que o preenchimento deste formulário não garante automaticamente a sua participação. Entraremos em contato diretamente caso a sua candidatura seja selecionada para avançar para a próxima fase.",
        "TAXAS DE PARTICIPAÇÃO": "Para validar a inscrição, é necessário o pagamento de uma taxa de participação. Esta taxa é fixa e não negociável. Os candidatos selecionados serão informados sobre os procedimentos de pagamento. Cada evento tem uma taxa de inscrição diferente.",
        "POLÍTICA DE PAGAMENTOS": "Todas as transações devem ser realizadas exclusivamente através do sistema do evento. Qualquer tentativa de utilizar outros métodos de pagamento resultará na rescisão imediata da inscrição e inclusão na lista negra do evento.",
        "Estamos entusiasmados para analisar a sua inscrição. Se for selecionado, entraremos em contato diretamente por e-mail.", "Na próxima página, encontrará todas as informações necessárias para concluir a sua inscrição."
    </div>
</template>

<script setup>

</script>