<script setup>
import { ref, onMounted, computed } from 'vue'
const formStore = useFormStore()

import 'js-circle-progress'

import Olho from '../Mine/Olho.vue'

const year = new Date().getFullYear()

const prev = () => {
  console.log('prev', formStore.stepCurrent)
  formStore.setStep(formStore.stepCurrent-1);
//   formStore.stepPrev();
}

const next = () => {
  console.log('next', formStore.stepCurrent)
  formStore.stepNext();
  //   formStore.setStep(formStore.stepCurrent+1);
    // if(formStore.stepCurrent == 0 && formStore.unlocked == 0){
    //     formStore.setStep(1);
    // } else {
    //     formStore.stepNext();
    // }
}
</script>

<template>
    <div>
        <transition name="top-bar">
            <div v-if="formStore.stepCurrent > 0" class="fixed top-0 left-0 right-0 h-24 grid grid-cols-4">
                <div class="col-start-2 h-full px-10 py-24 flex items-center justify-between">
                    <div class="">
                        <div class="text-7xl">{{formStore.stepCurrent }}/{{formStore.steps.length }}</div>
                        <div class="col-start-2 text-xl">{{formStore.step.text}}</div>
                    </div>
                </div>
                <div class="h-full p-6 flex items-center justify-between">
                    <div class="font-bold uppercase leading-[.95em] tracking-tight text-2xl">{{formStore.stepCurrent }}/{{formStore.steps.length }}<br>{{formStore.percentagem}}%</div>
                    <div class="font-bold uppercase leading-[.95em] tracking-tight text-2xl"><circle-progress :value="formStore.percentagem" max="100" textFormat="percent"></circle-progress></div>
                </div>
                <div class="h-full p-6 flex items-center justify-between">
                    <button @click="prev" class="group flex items-center space-x-3">
                        <svg class="size-10 text-black/20">
                            <use xlink:href="/storage/img/new-icons.svg?file#carbon-arrow-left" /></svg>
                        <div class="hidden group-hover:flex font-bold uppercase leading-[.95em] tracking-tight text-lg">Voltar
                        </div>
                    </button>
                    <button @click="next" class="group flex items-center space-x-3">
                        <div class="hidden group-hover:flex font-bold uppercase leading-[.95em] tracking-tight text-lg">Avançar
                        </div>
                        <svg class="size-12 text-black">
                            <use xlink:href="/storage/img/new-icons.svg?file#carbon-arrow-right" /></svg>
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<style scoped>
circle-progress::part(base) {width: 48px; height: auto;}
</style>
