<template>
    <div>
        <div class="relative w-full grid grid-cols-6 mt-12">
            <div class="relative col-start-3 col-span-4 rounded-tl-3xl bg-white shadow-2xl">
                <div class="absolute inset-0 flex items-center justify-center">
                    <img class="size-[70%] opacity-[.015] ml-5 sm:ml-6 md:ml-8 lg:ml-10 xl:ml-12 2xl:ml-16"
                        src="/storage/img/moyg-eye.svg" alt="">
                </div>
                <div class="relative conteudo-y pb-56 conteudo-l grid grid-cols-2 gap-6">
                    <div class="col-span-2 flex items-center space-x-3">
                        <div class="area-icon__wrapper">
                            <svg class="area-icon">
                                <use :xlink:href="'/storage/img/new-icons.svg?file#' + formStore.step.icon" /></svg>
                        </div>
                        <div class="text-sm mb-1 text-black/50 flex justify-between items-end">
                            <div>Formulário de Inscrição Food Trucks<br><strong
                                    class=" text-verde-500">{{ formStore.step.title }}</strong></div>
                        </div>
                    </div>
                    <div v-for="field in formStore.step.fields" class="relative z-0 mb-6 w-full group"
                        :class="{invalid: field.invalid, 'col-span-full': field.cols }">
                        <input :type="field.type" :name="field.slug" class="text-input peer"
                            :placeholder="field.placeholder ?? ''" :required="field.required ? true : false"
                            v-model="field.valor" />
                        <label :for="field.slug" class="text-label">{{field.title }} {{field.cols }} *</label>
                    </div>
                </div>
            </div>
        </div>
        <button @click="valida" class="absolute bottom-20 left-1/2 -translate-x-1/2 w-1/2 h-16 flex items-center justify-center rounded-full text-white  bg-verde-500 hover:bg-verde-600 shadow-2xl font-bold ">
            <span>Submeter</span>
        </button>

    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
const formStore = useFormStore()


const valida = () => {
    formStore.step.fields.forEach(field => {
        if(!field.valor) field.invalid = true
    });
}

const preenchidos = computed(() => {
    var campos = formStore.step.fields.filter((field) => field.valor)
    return campos.length
});
</script>

<style scoped>
  .text-input {
    @apply block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-verde-600
  }
.text-label {
    @apply absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-verde-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6
}

.area-icon__wrapper {
    @apply flex size-16 shrink-0 items-center justify-center rounded-full bg-verde-500/10;
    .area-icon {
    @apply size-8 text-verde-500;
    }
}

  .invalid {
    .text-input {
        @apply text-red-500 border-red-500 focus:border-red-500
    }
    .text-label {
        @apply text-red-500 peer-focus:text-red-500
    }

    .area-icon__wrapper {
    @apply bg-red-500/10;
    .area-icon {
    @apply text-red-500;
    }
    }
  }
</style>
