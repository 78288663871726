<template>
    <!-- <PerfectScrollbar> -->
        <div class="relative pt-36">
            <!-- <div class="absolute top-1/2 right-0 -translate-y-1/2  translate-x-1/3">

                <svg class="size-[100dvh] rotate-12 text-black"><use :xlink:href="'/storage/img/new-icons.svg?file#' + step.icon" /></svg>
            </div> -->
            <div class="conteudo grid grid-cols-12 gap-8">
                <div class="col-span-9 text-7xl">{{ step.title }}</div>
                <div class="col-span-9 text-xl">{{ step.text }}</div>
            </div>
                
            <component v-if="step.number == formStore.step.number" :is="componentMap[step.component]"></component>
        
                
        </div>
    <!-- </PerfectScrollbar> -->
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
const formStore = useFormStore()



defineProps({
    step: Object
})

import InfoEmpresa from './InfoEmpresa.vue'
import InfoFoodtruck from './InfoFoodtruck.vue'
import Documentos from './Documentos.vue'
import Confirmacao from './Confirmacao.vue'

const componentMap = {
  InfoEmpresa: InfoEmpresa,
InfoFoodtruck: InfoFoodtruck,
Documentos: Documentos,
Confirmacao: Confirmacao
};

const primeiro = () => {
    if(formStore.unlocked < 1) formStore.unlocked = 1;
    formStore.stepCurrent = 1;
}
</script>

<style scoped>
  
</style>
