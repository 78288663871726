<script setup>
import {ref, computed} from 'vue'
import { useFormNewStore } from '@/stores/formNew.js'
const formNewStore = useFormNewStore()



import { useForm, configure } from 'vee-validate'

import * as yup from 'yup';



const props = defineProps({
    step: Object,
    field: Object
})

yup.setLocale({
  mixed: {
    default: 'Não é válido',
    required: 'Este campo é obrigatório.'
  },
  number: {
    min: 'Deve ser superior a 0',
  },
  string: {
    length: 'Deve ter exatamente ${length} caracteres',
    min: 'Deve ter pelo menos ${min} caracteres',
    max: 'Deve ter no máximo ${max} caracteres',
    email: 'Deve ser um e-mail válido', // Custom message for invalid email
    url: 'Deve ser uma URL válida',
    trim: 'Não deve conter espaços no início ou no fim.',
    lowercase: 'Deve estar em minúsculas',
    uppercase: 'Deve estar em maiúsculas',
    // Other string type messages
  },
});


const regras = {}
if(props.field.type == 'email'){
regras[props.field.slug] = yup.string().email().required().label(props.field.title)
} else if(props.field.type == 'number'){
regras[props.field.slug] = yup.number().required().min(0).label(props.field.title)
} else if(props.field.required){
regras[props.field.slug] = yup.string().required().label(props.field.title)
}




const { values, errors, meta, defineField } = useForm({
  validationSchema: yup.object(regras),
});

const [input, inputAttrs] = defineField(props.field.slug);
input.value = props.field.valor

// formNewStore.step.mensagemErro = computed(() => {
//     // var campos = formNewStore.step.fields.filter((field) => field.valor)
//     // return campos.length
//     return preenchidos.value ? false : 'Não tem qualquer evento/sessão selecionado.'
// });
props.field.valor = computed(() => {
    return values[props.field.slug]
});
props.field.valid = computed(() => {
    return meta.value.valid
});
props.field.errorMessage = computed(() => {
    return errors.value[props.field.slug]
});
</script>

<template>
    <!-- <input v-model="input" v-bind="inputAttrs" />

<pre>values: {{ values }}</pre>
<pre>errors: {{ errors }}</pre>
<pre>errors: {{ meta }}</pre>


    <pre>{{ field }}</pre>
    ola -->


    <label class="block">
        <span class="text-black text-xs font-bold" :class="{'text-red-500': !field.valid}">{{field.title }} {{field.required ? '*' : '' }}</span>
        <p v-if="field.slug == 'instagram'" class="text-xs pb-4">Por favor insira o perfil de instagram da truck. Se não tiver instagram pode adicionar o facebook ou website.</p>
        <input v-model="input" v-bind="inputAttrs" :type="field.type" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:border-neutral-300 focus:ring-neutral-200" :class="{'border-red-500 focus:border-red-300 focus:ring-red-200': !field.valid}" placeholder="" :min="field.type=='number' ? 0 : false">
        <span v-if="field.errorMessage && !field.valid" class="text-xs" :class="{'text-red-500': !field.valid}">{{field.errorMessage }}</span>
    </label>
</template>
<style scoped>
</style>