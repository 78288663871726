<template>
    <!-- <PerfectScrollbar> -->
        <div class="p-6">
            <div class="font-bold uppercase">Processo de<br>Inscrição</div>
            <div class="text-sm mt-3">Esteja ciente de que o preenchimento deste formulário não garante automaticamente a sua participação. Entraremos em contato diretamente caso a sua candidatura seja selecionada para avançar para a próxima fase.</div>
        </div>
        <div class="p-6">
            <div class="font-bold uppercase">Taxas de<br>Participação</div>
            <div class="text-sm mt-3">Para validar a inscrição, é necessário o pagamento de uma taxa de participação. Esta taxa é fixa e não negociável. Os candidatos selecionados serão informados sobre os procedimentos de pagamento. Cada evento tem uma taxa de inscrição diferente.</div>
        </div>
        <div class="p-6">
            <div class="font-bold uppercase">Política de<br>Pagamentos</div>
            <div class="text-sm mt-3">Todas as transações devem ser realizadas exclusivamente através do sistema do evento. Qualquer tentativa de utilizar outros métodos de pagamento resultará na rescisão imediata da inscrição e inclusão na lista negra do evento.</div>
        </div>
    <!-- </PerfectScrollbar> -->
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useFormStore } from '@/stores/form.js'
const formStore = useFormStore()



const primeiro = () => {
    formStore.setStep(1)
}
</script>

<style scoped>
  
</style>
