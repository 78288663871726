// import '../css/front.css';

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
// import 'vue3-perfect-scrollbar/style.css';

// import 'flowbite';

// Import modules...
import { createApp } from 'vue/dist/vue.esm-bundler.js';
// import PrimeVue from 'primevue/config';
// import ToastService from 'primevue/toastservice';
// import Tooltip from 'primevue/tooltip';
// import StyleClass from "primevue/styleclass";
// import Lara from '@/presets/aura';

// import 'primeicons/primeicons.css'
// Vuetify
// import 'vuetify/styles'
// import { createVuetify } from 'vuetify'
// import * as components from 'vuetify/components'
// import * as directives from 'vuetify/directives'

import Front from './Front.vue'

// const vuetify = createVuetify({
//     components,
//     directives,
// })

const app = createApp(Front);
// initialize Pinia
const pinia = createPinia();
// Use the plugin
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
// app.use(PrimeVue);
// app.use(ToastService);
// app.component("Tooltip", Tooltip);
// app.directive('tooltip', Tooltip);
// app.directive("styleclass", StyleClass);

// import 'primevue/resources/themes/lara-light-green/theme.css';
app.mount("#app");
