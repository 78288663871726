<script setup>
import { ref, onMounted, computed } from 'vue'
import { useFormNewStore } from '@/stores/formNew.js'
const formNewStore = useFormNewStore()

import DropzoneInput from '@/Last/Form/DropzoneInput.vue'

formNewStore.step.mensagemErro = computed(() => {
    if(!formNewStore.step.valid) return 'Todos os campos marcados com (*) são obrigatórios.'
    return false
});

formNewStore.step.valid = computed(() => {
    return formNewStore.ficha_tecnica.id && formNewStore.memoria_descritiva.id
});
</script>

<template>
<div class="conteudo conteudo-mini-y">

<div class="min-h-full flex flex-col justify-between">
    <div class="grid grid-cols-6 gap-6">
        <div v-for="(field, fieldIndex) in formNewStore.documentos"
            :class="{'col-span-full': field.type == 'text' || field.type == 'file', 'col-span-3': field.type == 'number', 'col-span-2': field.type == 'radio' }">
            <div v-if="field.type == 'file'" class="input-wrapper">
                <DropzoneInput :key="'field-'+field.id" :field="field" :field-index="fieldIndex"></DropzoneInput>
                    </div>
                </div>
                </div>
                </div>
                </div>

</template>

