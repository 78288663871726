<template>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed earum molestias veritatis aspernatur dignissimos quasi similique reprehenderit quod optio minus accusantium perspiciatis, culpa illo? Nulla quisquam incidunt temporibus veniam beatae?
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
const formStore = useFormStore()

</script>

<style scoped>
  
</style>
